#video-scoreboard-directive {

  $outerScoreboardColor: $vm-white-color-one;
  $outerScoreboardBorderColor: $vm-white-color-one;
  $innerScoreboardColor: $vm-black-color-one;
  $innerScoreboardBackground: $vm-white-color-one;

  position: relative;

  .video-scoreboard-table {
    @extend .vm-table-fixed;
    color: $outerScoreboardColor;
    background-color: $vm-black-color-one;
    border: 3px solid $outerScoreboardBorderColor;
    border-radius: 10px;
  }

  .video-scoreboard-row {
    @extend .vm-table-row;
  }

  .video-score {
    @extend .vm-table-cell-center;
    padding: 2px;
    width: 27%;
  }

  .video-score-team {
    font-size: 10px;
    font-weight: 100;
  }

  .video-score-number {
    font-weight: 800;
    font-size: 15px;
  }

  .video-match-details {
    @extend .vm-table-cell-center;
    width: 46%;
  }

  .video-match-details-table {
    @extend .vm-table-fixed;
    color: $innerScoreboardColor;
    background-color: $innerScoreboardBackground;
    border-radius: 5px;
  }

  .video-match-details-row {
    @extend .vm-table-row;
  }

  .video-match-games-won {
    @extend .vm-table-cell-center;
    width: 40%;
  }

  .videoo-match-won-number {
    font-weight: 800;
  }

  .video-match-won-text {
    font-size: 8px;
  }

  .video-match-set {
    @extend .vm-table-cell-center;
    font-size: 20px;
    font-weight: 800;
    padding: 0 5px;
    width: 30%;
  }

  &.isFullscreen {

    .video-scoreboard-table {
      background-color: transparent;
      border: 3px solid hsla(0, 0%, 100%, 0.5);
      transition: all 0.25s ease-in-out;
    }

    .video-match-details-table {
      background-color: hsla(0, 0%, 100%, 0.5);
      transition: all 0.25s ease-in-out;
    }

    &:hover {
      .video-scoreboard-table {
        background-color: $vm-video-overlay-dark-one;
        border: 3px solid $outerScoreboardBorderColor;
        transition: all 0.25s ease-in-out;
      }

      .video-match-details-table {
        background-color: $innerScoreboardBackground;
        transition: all 0.25s ease-in-out;
      }
    }

  }
}
