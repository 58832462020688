#portal-match {

  position: relative;

  #portal-match-status-container {
    position: fixed;
    left: 170px;
    top: 0;
    right: 425px;
    z-index: 1044;
  }

  .portal-match-status-table {
    @extend .vm-table-fixed;
  }

  .portal-match-status-row {
    @extend .vm-table-row;
  }

  .portal-match-status-icons {
    @extend .vm-table-cell-center;
    width: 50px;
    height: 60px;
  }

  .portal-match-status-info {
    @extend .vm-table-cell-left;
    color: white;
    height: 60px;
  }

  .match-status-teams {
    font-size: 16px;
  }

  .team-abbreviation-text {
    &:hover {
      color: $vm-hover-color-three;
    }
  }

  .match-status-score {
    font-size: 16px;
  }

  .match-status-score-details {
    font-size: 12px;
    font-weight: 100;
  }

  .match-status-venue {
    font-size: 12px;
    font-weight: 100;
  }

  #portal-match-video-column {
    float: left;
    width: 75%;
    @media (max-width: $mediumBreakpoint) {
      float: none;
      width: 100%;
    }
  }

  .vm-match-video-player-directive-container {

  }

  .video-details-panel-container {
    position: relative;
    color: $vm-white-color-one;
    background-color: $vm-black-color-one;
  }

  .video-event-details-directive-container {
    float: left;
  }

  .video-scoreboard-directive-container {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 200px;
  }

  .video-action-menu-directive-container {
    float: right;
  }

  .video-controls-panel-directive-container {
    color: $vm-white-color-one;
    background-color: $vm-black-color-one;
  }

  .vm-match-comments-directive-container {
    margin-top: 5px;
  }

  #portal-match-controls-column {
    float: left;
    width: 24%;
    margin-left: 1%;
    @media (max-width: $mediumBreakpoint) {
      float: none;
      width: 100%;
      margin-left: 0;
    }
  }

  .actions-heading {
    font-weight: bold;
    padding-top: 10px;
  }

  .vm-match-actions-directive-container {
    padding: 5px 10px;
    border: 1px solid $vm-border-color-one;
    border-radius: 5px;
  }

}
