.match-status-icons-directive {

  position: relative;

  .will-be-analyzed-icon {
    font-size: 14px;
    @extend .hoverable-green-text-one;
  }

  .will-not-be-analyzed-icon {
    font-size: 14px;
    @extend .hoverable-red-text-one;
  }
}
