.vm-collapsible-directive {

  .section-header {
    @extend .vm-heading-3;
    background-color: darken($vm-light-gray-color-one, 10%);
    height: 35px;
    line-height: 35px;
    padding: 0 5px;
    margin: 5px 0;
    cursor: pointer;

    &:hover {
      background-color: darken($vm-light-gray-color-one, 20%);
    }
  }

  .section-content {

  }

}
