#portal-notifications {

  position: relative;

  .notification {
    cursor: pointer;

    &:hover {
      background-color: $vm-hover-color-two;
    }
  }

  .unread {
    background-color: $vm-hover-color-one;
  }
}
