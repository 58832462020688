#vm-admin-roster-player-modal {

  .input-row {
    margin: 10px 0;
    width: 100%;
  }

  .date-input {
    float: left;
    width: 50%;
  }

  .time-input {
    float: left;
    width: 40%;
    padding-left: 5px;
  }

  .timezone-input {
    float: left;
    width: 10%;
    line-height: 35px;
    text-align: center;
  }

  .email-container {
    background-color: $vm-light-gray-color-two;
    padding: 10px;
    border-radius: 5px;
  }

  .error-text {
    font-size: 10px;
    color: $vm-red-color-one;
    text-align: left;
  }

  .saving-error {
    font-size: 12px;
    text-align: right;
  }

  .delete-text-container {
    margin: 5px 0;
    text-align: center;
  }

  .warning-text {
    color: $vm-red-color-one;
  }

  .delete-roster-player-container {
    background-color: $vm-table-row-color-two;
    border-radius: 5px;
    margin: 10px 10px;
    padding: 20px;
    text-align: center;
  }
}
