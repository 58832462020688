.vm-match-actions-directive {

  position: relative;

  .vm-match-actions-container {

  }

  .button-table {
    @extend .vm-table-fixed;
  }

  .button-container {
    @extend .vm-table-row;
  }

  .button-description {
    @extend .vm-table-cell-left;
    font-size: 11px;
    padding: 3px 0;
  }

  .description-text {
    margin-left: 10px;
  }

  .button-action {
    @extend .vm-table-cell-left;
    padding: 3px 0;
  }

  .button-text {
    margin-left: 3px;
  }

  .document-button {
    display: inline-block;
    width: 100%;
    font-size: 11px;
  }

  hr {
    margin: 3px 0;
  }

  #actualDvwLink {
    display: none;
  }

  #actualDvwUpload {
    display: none;
  }

  .send-to-hudl-button {
    background: #ff6300;
    border-color: #ff6300;
  }

  .hudl-logo {
    width: 1.2em;
    height: 1.2em;
    fill: white;
    position: relative;
    bottom: -0.2em;
    margin-left: 0.2em;
  }

  .hudl-icon .hudl-logo {
    fill: rgba(0, 0, 0, 0.54);
  }
  
  .is-loading-send-to-hudl {
    height: 1em;
    
    .loading-directive .spin-container {
      height: 1.5em;
      font-size: 1.5em;
    }
  }

}
