#scoring-table {
  position: relative;
  width: 100%;

  .update-scoring-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.65);
    z-index: 1;
  }

  .update-scoring-message-container {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: $vm-white-color-one;
    padding: 20px;
    border: 1px solid $vm-border-color-one;
    @extend .small-shadow;
    min-width: 500px;
  }

  .update-scoring-message {
    font-weight: 800;
  }

  .update-scoring-button {

  }

  .sortedCol {
    background-color: rgba(55, 161, 218, 0.65);
  }

  .header {
    height: 30px;
    line-height: 30px;
  }

  .columnGroupHeader {
    background-color: #464646;
    color: white;
    font-weight: bold;
    &:hover {
      background-color: #3d3b3b;
      color: #37a2db;
    }
    span.glyphicon {
      color: #75ff3d
    }
  }

  .columnHeader {
    background-color: #606060;
    color: white;
    font-weight: bold
  }

  .staticRow {
    background-color: #bbbbbb;
    font-weight: bold
  }

  .highlightedRow {
    background-color: #75ff3d;
    &:nth-child(2n+1) {
      background-color: #75ff3d;
    }
  }

  .hoveredRowOrCol {
    background-color: rgba(55, 161, 218, 0.75);
    &:nth-child(2n+1) {
      background-color: rgba(55, 161, 218, 0.75);
    }
  }

  .borderRightDivider {
    border-right: 1px solid rgb(210, 210, 210);
  }

  .hoveredRowAndCol {
    color: #ffffff;
    font-weight: bold;
    &:nth-child(2n+1) {
      colr: #ffffff;
      font-weight: bold;
    }
  }
}
