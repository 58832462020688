#portal-system-administration-rosters {

  .actions-header {
    margin: 10px 0;
  }

  .team-input {
    width: 30%;
    float: left;
  }

  .date-input {
    width: 10%;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
  }

  .update-roster-button {
    width: 15%;
    float: left;
  }

  .create-roster-player-button {
    width: 15%;
    float: right;
  }

  .duplicate-roster-numbers-container {
    color: $vm-red-color-one;
  }
}
