#report-confirm-modal {
  margin-top: 100px;

  .progress-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .send-progress {
    margin: auto;
    transition: width 1s ease-in-out;
  }

  .time-icon {
    font-size: 3em !important;
  }

  .email-area {
    margin-top: 20px;
  }

  .finished-text-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .message-text-container > h3 {
      // max-width: 80%;
      align-self: center;
    }
  }
}