.vm-option-drawer-directive {

  $leftColumnWidth: 50px;

  .vm-option-drawer-header-row {
    line-height: 40px;
    cursor: pointer;

    &:hover {
      color: $vm-primary-color-one;
    }

    &.disabled {
      color: gray;
      cursor: default;
    }
  }

  .vm-option-drawer-left-arrow {
    width: $leftColumnWidth;
    float: left;
    text-align: center;
  }

  .vm-option-drawer-header-title {
    float: left;
  }

  .vm-option-drawer-header-title-text {

  }

  .vm-option-drawer-selected {
    float: right;
    padding-right: 5px;
  }

  .vm-option-drawer-selected-text {
    color: gray;
  }

  .vm-option-drawer-right-arrow {
    float: right;
  }

  .vm-option-drawer-table {
    border-top: 1px solid white;
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .vm-option-drawer-row {
    display: table-row;
    height: 20px;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: $vm-primary-color-one;
    }
  }

  .vm-option-drawer-is-active-cell {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 50px;
  }

  .vm-option-drawer-is-active-text {

  }

  .vm-option-drawer-label-cell {
    display: table-cell;
    vertical-align: middle;
    font-size: 13px;
  }

  .vm-option-drawer-label-cell-text {

  }


}
