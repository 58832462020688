#send-to-hudl-modal {

    .share-message {
        padding: 5px 0px 15px 0px;
    }

    .no-data-container {
        padding: 20px;
        text-align: center;
    }

    .sent-to-hudl-container {
        padding: 20px;
        text-align: center;
    }

    .sent-to-hudl-text {
        padding: 15px;
    }

    .empty-header {
        padding: 15px;
        border: none;
    }
}