.advanced-filters-section {

  $buttonHeight: 35px;
  $buttonPadding: 5px;

  .filtering-note {
    padding: 10px;
    margin: 5px 0;
  }

  .button-group {
    background-color: $vm-light-gray-color-one;
    margin: 5px 0;
  }

  .advanced-filter-button {
    display: inline-block;
    float: left;
    height: $buttonHeight + (2 * $buttonPadding);
    min-width: 100px;
    padding: $buttonPadding;
  }

  .advanced-filter-button-inner {
    @extend .hoverable-light-gray-transparent-blue-background-one;
    text-align: center;
    line-height: $buttonHeight;
    background-color: $vm-white-color-one;
    padding: 0 10px;
    border-radius: 3px;

    &.active {
      background-color: $vm-primary-color-one;
    }
  }

}
