vm-input-box {
  $borderRadius: 5px;
  $inputBoxHeight: 35px;
  $borderSize: 1px;

  @extend .vm-table;

  .input-box-top {

  }

  .input-box-top-text {
    font-weight: 800;
  }

  .input-box-container {
    position: relative;
    @extend .vm-table-row;
    height: $inputBoxHeight;
  }

  input {
    line-height: normal;
  }

  .input-box-pre {
    @extend .vm-table-cell-center;
    background-color: $vm-light-gray-color-one;
    border: $borderSize solid $vm-border-color-one;
    padding: 0 10px;
    font-weight: 800;
    font-size: 11px;
    width: 20%;

    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
      border-right-width: 0;
    }

    &.isClickable {
      &:hover {
        cursor: pointer;
      }
    }

    &.small-size {
      width: 80px;
    }

    &.medium-size {
      width: 120px;
    }

    &.large-size {
      width: 160px;
    }
  }

  .input-box-field {
    position: relative;
    background-color: $vm-white-color-one;
    @extend .vm-table-cell;
    border: $borderSize solid $vm-border-color-one;
    padding: 0 5px;
    text-align: left;

    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }

    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }

    &.input-is-not-valid {
      background-color: $vm-not-valid-color-one;
    }
  }

  .input-box-field-input {
    width: 100%;
    outline-width: 0;
    border-width: 0;
    height: $inputBoxHeight;

    &.show-cancel-circle {
      width: 90%;
      width: calc(100% - 25px);
    }

    &.input-is-not-valid {
      background-color: $vm-not-valid-color-one;
    }
  }

  .input-box-field-cancel {
    background-color: $vm-white-color-one;
    border-radius: 15px;
    position: absolute;
    height: 30px;
    width: 30px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 20px;

    &:hover {
      @extend .hoverable-gray-red-text-one;
    }
  }

  .input-box-post {
    @extend .vm-table-cell-center;
    background-color: $vm-primary-color-one;
    border: $borderSize solid $vm-border-color-one;
    padding: 0 10px;
    font-weight: 800;
    color: $vm-white-color-one;


    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
      border-left-width: 0;
    }

    &.isClickable {
      @extend .hoverable-blue-background-one;
    }

    &.small-size {
      width: 80px;
    }

    &.medium-size {
      width: 120px;
    }

    &.large-size {
      width: 160px;
    }
  }

  .input-box-post-text {

  }
}
