.quick-tip {
  $closeCircleSize: 25px;

  position: relative;
  background-color: $vm-secondary-color-one;
  padding: 10px 20px 10px 0;
  border-radius: 5px;
  margin-top: ($closeCircleSize / 2) + 15px;
  margin-right: $closeCircleSize / 2;

  .quick-tip-container {

  }

  .quick-tip-row {
    display: table-row;
  }

  .quick-tip-icon-container {
    display: table-cell;
    width: 40px;
    vertical-align: top;
    text-align: center;
  }

  .quick-tip-icon {
    font-size: 20px;
    color: $vm-primary-color-one;
  }

  .quick-tip-text-container {
    display: table-cell;
    vertical-align: top;
  }

  .quick-tip-tip-preface {
    font-weight: 800;
    color: $vm-color-black-0;
  }

  .quick-tip-tip {

  }

  .quick-tip-next-container {
    text-align: right;
    margin-top: 10px;
  }

  .quick-tip-next {
    font-weight: 800;
    color: $vm-color-black-0;
    cursor: pointer;

    &:hover {
      color: $vm-primary-color-one;
    }
  }

  .quick-tip-close-container {
    background-color: $vm-secondary-color-one;
    position: absolute;
    width: $closeCircleSize;
    height: $closeCircleSize;
    border-radius: $closeCircleSize / 2;
    top: -$closeCircleSize / 2;
    right: -$closeCircleSize / 2;
    cursor: pointer;

    &:hover {
      color: $vm-primary-color-one;
    }
  }

  .quick-tip-close-x {
    width: 100%;
    text-align: center;
    line-height: $closeCircleSize;
  }
}
