.video-settings-directive {

  .video-settings-container {
    padding: 15px;
  }

  .settings-row {
    height: 40px;
    line-height: 40px;
  }
}
