#app-container {
  position: relative;
  font-family: 'Lato', "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  color: rgba(0, 0, 0, .54);
  min-height: 100vh;

  #app-fixed-header {
    background-color: hsl(55, 100%, 76%);
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 5000;
  }

  #app-relative-body {
    position: relative;
    min-height: 100vh;
    width: 100%;
  }

  #app-fixed-footer {
    background-color: hsl(0, 0%, 36%);
    z-index: 5000;
    bottom: 0px;
    width: 100%;
    position: fixed;
  }

  .app-body-content-narrow {
    position: relative;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    max-width: 900px;
  }

  .app-body-content-normal {
    position: relative;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    max-width: 1350px;
  }

  .app-body-content-wide {
    position: relative;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    max-width: 1680px;
  }
}
