#beach-lineup-modal {

  .generic-modal-body {
    padding: 15px 30px;
  }

  .body-message {

  }

  .teams-container {
    @extend .vm-table-fixed;
  }

  .team-container {
    @extend .vm-table-cell-center;
    padding: 10px;

    &:nth-child(1n + 2) {
      border-left: 1px solid $vm-border-color-one;
    }
  }

  .team-type-text {

  }

  .team-text {
    font-weight: bold;
    margin-bottom: 10px;
  }

}
