.vm-callout-container {
  $calloutArrowSize: 10px;
  $calloutBorderRadius: 5px;
  $calloutBorderSize: 0px;

  $calloutTextColor: hsl(0, 0%, 97%);;
  $calloutTextColor_hover: $vm-color-white-100;
  $calloutBackgroundColor: hsla(0, 0%, 0%, 0.8);
  $calloutBackgroundColor_hover: $vm-primary-color-one;
  $calloutBorderColor: $vm-color-white-100;
  $calloutArrowColor: $calloutBackgroundColor;

  position: relative;

  .vm-callout-hover {
    cursor: pointer;

    &:hover {
      color: $vm-hover-color-three;
    }
  }

  .vm-options-container {
    position: absolute;
    margin-top: $calloutArrowSize;
    min-width: 250px;
    right: 0;
    z-index: 10;

    &.no-top-arrow {
      margin-top: 0;
    }
  }

  .top-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-width: 0px $calloutArrowSize $calloutArrowSize $calloutArrowSize;
    border-color: transparent transparent $calloutArrowColor transparent;
    border-style: solid;
    top: -$calloutArrowSize;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .bottom-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-width: $calloutArrowSize $calloutArrowSize 0 $calloutArrowSize;
    border-color: $calloutArrowColor transparent transparent transparent;
    border-style: solid;
    bottom: -$calloutArrowSize;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .left-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-width: $calloutArrowSize $calloutArrowSize $calloutArrowSize 0;
    border-color: transparent $calloutArrowColor transparent transparent;
    border-style: solid;
    left: -$calloutArrowSize;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  .right-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-width: $calloutArrowSize 0 $calloutArrowSize $calloutArrowSize;
    border-color: transparent transparent transparent $calloutArrowColor;
    border-style: solid;
    right: -$calloutArrowSize;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  .vm-callout-option-table {
    display: table;
    width: 100%;
    table-layout: fixed;
    text-align: left;
    border-radius: $calloutBorderRadius;
    border: $calloutBorderSize solid $calloutBorderColor;
    box-shadow: 2px 2px 2px $vm-color-black-0a25;
    background-color: $vm-video-overlay-dark-one;
  }

  .vm-callout-template-container {
    border-radius: $calloutBorderRadius;
    border: $calloutBorderSize solid $calloutBorderColor;
    box-shadow: 2px 2px 2px $vm-color-black-0a25;
    background-color: $calloutBackgroundColor;
  }

  .vm-callout-directive-template-container {
    border-radius: $calloutBorderRadius;
    border: $calloutBorderSize solid $calloutBorderColor;
    box-shadow: 2px 2px 2px $vm-color-black-0a25;
    background-color: $calloutBackgroundColor;
  }

  .vm-callout-option-row {
    display: table-row;
    height: 30px;
    padding: 10px;
    background-color: $calloutBackgroundColor;
    cursor: pointer;
    color: $calloutTextColor;

    &:first-child {
      div {
        border-top-left-radius: $calloutBorderRadius;
        border-top-right-radius: $calloutBorderRadius;
      }
    }

    &:last-child {
      div {
        border-bottom-left-radius: $calloutBorderRadius;
        border-bottom-right-radius: $calloutBorderRadius;
      }
    }

    &:hover {
      background-color: $vm-hover-color-two;
      color: $calloutTextColor_hover;
    }
  }

  .vm-callout-option-cell {
    background-color: transparent;
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;
  }

  // font sizes
  .small-font-size {
    font-size: 10px;
  }

  .medium-font-size {
    font-size: 14px;
  }

  // max height
  .no-max-height {
    max-height: none;
    overflow-y: visible;
  }

  .scrollable-max-height {
    overflow-y: auto;
  }

}
