.vm-comment-directive {

  $newCommentRowTopPadding: 10px;

  position: relative;

  .vm-comment-container {
    border-bottom: 1px solid $vm-border-color-one;
  }

  .vm-comment-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .vm-comment-row {
    display: table-row;
    cursor: pointer;

    &:hover {
      background-color: $vm-hover-color-one;
    }
  }

  .vm-comment-left-column {
    display: table-cell;
    vertical-align: top;
    width: 15%;
    padding-top: $newCommentRowTopPadding;
  }

  .vm-comment-name-text {
    font-size: 12px;
    font-weight: 800;
  }

  .vm-comment-display-timestamp {
    font-size: 11px;
  }

  .vm-comment-right-column {
    display: table-cell;
    vertical-align: top;
    padding-top: $newCommentRowTopPadding;
    padding-left: 10px;
  }

  .vm-comment-body-container {
    float: left;
    width: 80%;
  }

  .vm-comment-body {

  }

  .vm-comment-body-text {
    font-weight: 800;
  }

  .vm-comment-moment {
    float: left;
    width: 20%;
    text-align: right;
  }

  .trash-icon {
    @extend .hoverable-gray-red-text-one;
  }

  .vm-comment-moment-text {
    font-size: 11px;
  }

  .vm-comment-reply-container {

  }

  .reply-view-text {
    @extend .hoverable-blue-text-one;
    font-size: 12px;

    &:hover {
      font-weight: 800;
    }
  }

  .vm-comment-reply-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .vm-comment-reply-row {
    display: table-row;
  }

  .tag-text {
    float: left;
    line-height: 35px;
    font-size: 12px;
  }

  .no-tag-text {
    color: $vm-red-color-one;
  }

  .reply-tag {
    float: left;
  }

  .reply-input-box {
    padding-bottom: 10px;
  }

  .reply-post-message {

  }

  .reply-post-message-text {
    color: $vm-red-color-one;
  }
}

.last-active-comment {
  .vm-comment-directive {
    .vm-comment-left-column {
      border-bottom: 1px solid $vm-black-color-one;
    }

    .vm-comment-right-column {
      border-bottom: 1px solid $vm-black-color-one;
    }
  }
}
