.generic-table-two-container {
  position: relative;
  width: 100%;

  .generic-table-two-table {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid $vm-border-color-one;

    &.hide-border {
     border-width: 0;
    }

    &.square-top {
     border-top-left-radius: 0px;
     border-top-right-radius: 0px;
    }
  }

  .generic-table-two-header-group {
    width: 100%;
    background-color: $vm-table-header-color-one;
  }

  .generic-table-two-header {
    width: 100%;
    font-weight: bold;
  }

  .generic-table-two-header-cell {
    position: relative;
    float: left;
    height: 36px;
    text-align: center;

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
  }

  &.square-top {
    .generic-table-two-header-cell {
      &:first-child {
        border-top-left-radius: 0px;
      }

      &:last-child {
        border-top-right-radius: 0px;
      }
    }
  }

  .generic-table-two-header-cell-text {
    position: absolute;
    display: block;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .generic-table-two-row-group {
    width: 100%;
  }

  .generic-table-two-row {
    color: hsla(0, 0%, 30%, 1);
    font-weight: 100;
    font-size: 13px;
    border-bottom: 1px solid $vm-border-color-one;

    &:nth-child(2n) {
     background-color: $vm-table-row-color-one;
     &:hover {
       background-color: $vm-hover-color-one;
     }
    }
    &:nth-child(2n+1) {
     background-color: $vm-table-row-color-two;
     &:hover {
       background-color: $vm-hover-color-one;
     }
    }

    &.highlightRow {
     background-color: $vm-primary-color-one;

     &:hover {
       background-color: $vm-primary-color-one;
     }
    }

    &.lastRow {
      border-bottom-width: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .generic-table-two-row-cell-container {
    float: left;
  }

  .generic-table-two-row-cell {
    @extend .vm-table;
  }

  .generic-table-two-row-cell-text {
    @extend .vm-table-cell-center;
    text-align: center;
    height: 54px;
  }

  .no-row-data-container {

  }

  .no-row-data-cell {

  }

  .generic-table-two-footer-group {
    width: 100%;
  }

  .generic-table-two-footer {
    width: 100%;
  }

  .generic-table-two-pagination {
    overflow: hidden;
  }
}
