$headerHeight: 60px;
$leftMenuWidth: 80px;
$footerHeight: $headerHeight + 65px;

#portal-app-container {
  position: relative;

  &.app-blur {
    filter: blur(10px) grayscale(50%);
  }

  .app-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $vm-modal-backdrop-color;
    z-index: 5000;
  }

  #portal-app-header {
    position: fixed;
    top: 0px;
    height: $headerHeight;
    width: 100%;
    z-index: 1043;
  }

  #portal-app-body {
    position: relative;
    padding-top: $headerHeight;
  }

  #portal-app-body-left-menu-container {
    background-color: hsl(0, 0%, 85%);
    position: fixed;
    width: $leftMenuWidth;
    top: $headerHeight;
    bottom: 0px;
    z-index: 1042;
  }

  #portal-app-body-right-content-container {
    padding-left: $leftMenuWidth;
    &.hidden-menu {
      padding-left: 0;
    }
  }

  #portal-app-body-ui-view {
    position: relative;
    min-height: calc(100vh - #{$footerHeight});
  }

  #portal-app-body-footer {
    .disclaimer {
      font-size: 10px;
    }

    .policy {
      color: #3db4f3;
      font-size: 12px;
      a {
        color: #3db4f3;
      }
    }

    .copyright {
      color: #cbcbcb;
      a {
        color: #cbcbcb;
      }
    }

    .secondary-disclaimer {
      .copyright {
        color: #3db4f3;
        a {
          color: #3db4f3;
        }
      }
    }
  }

  #portal-progress-container {
    position: fixed;
    width: 300px;
    bottom: 10px;
    left: 90px;
    padding: 15px;
    z-index: 1042;
    .uploadWarning {
      color: red;
    }
  }

  #portal-progress-close-container {
    text-align: right;
  }

  #portal-progress-close {
    cursor: pointer;

    &:hover {
      color: $vm-primary-color-one;
    }
  }
}
