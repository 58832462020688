#portal-system-administration-match-access {
  .left-column {
    width: 70%;
    float: left;
    padding-right: 10px;
  }

  .right-column {
    width: 30%;
    float: left;
    min-height: 500px;
    border-left: 1px solid black;
    padding-left: 10px;
  }

  .match-access-title {
    @extend .vm-heading-3;
  }

  .match-access-title-left {
    @extend .vm-heading-3;
    float: left;
  }

  .match-access-title-right {
    @extend .vm-heading-3;
    float: right;
  }

  .actions-container {
    padding-bottom: 10px;
  }

  .match-access-search-box-cell {
    @extend .vm-table-cell-left;
    width: 44%;
  }

  .match-access-date-filter-cell {
    @extend .vm-table-cell;
    width: 50%;
    &:nth-child(1n+2) {
      padding-left: 5px;
    }
  }

  .match-access-search-button-cell {
    @extend .vm-table-cell;
    width: 10%;
    padding-left: 5px;
  }

  .match-access-search-match-type-cell {
    @extend .vm-table-cell;
    width: 15%;
  }

  .search-button {
    width: 100%;
  }

  .match-access-entity-type-cell {
    @extend .vm-table-cell;
    width: 25%;
  }

  .match-access-entity-search-box-cell {
    @extend .vm-table-cell;
    width: 40%;
    padding-left: 5px;
  }

  .match-access-entity-execute-button-cell {
    @extend .vm-table-cell;
    width: 25%;
    padding-left: 5px;
  }

  .selected-matches-container {
    background-color: $vm-light-gray-color-one;
    padding: 10px;
    margin-bottom: 5px;
  }

  .selected-matches-list {
    max-height: 200px;
    overflow-y: auto;
  }

  .match-access-card {
    position: relative;
    padding: 10px;
    background-color: $vm-white-color-one;
    margin-bottom: 5px;
    font-size: 10px;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .card-match-teams-container {
    text-align: center;
  }

  .card-match-team {
    font-size: 11px;
    font-weight: 800;
  }

  .card-remove-container {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    @extend .hoverable-red-text-one;
  }

  .access-button {
    width: 100%;
    margin-top: 5px;
  }

  .textarea-notes {
    margin-top: 5px;
    width: 100%;
    resize: none;
    border-width: 0px;
  }

  .error-text {
    font-size: 10px;
    color: $vm-red-color-one;
  }
}
