.event-filter-selector-directive {

  $eventFilterRadius: 5px;

  position: relative;

  .selected-event-filter-container {
    border: 1px solid $vm-border-color-one;
    padding: 5px;
  }

  .selected-event-filter {
    overflow: hidden;
    float: left;
    margin: 5px;
  }

  .event-filter-name {
    background-color: $vm-primary-color-one;
    color: $vm-white-color-one;
    float: left;
    border-top-left-radius: $eventFilterRadius;
    border-bottom-left-radius: $eventFilterRadius;
    padding: 0 5px;
    line-height: 25px;
  }

  .event-filter-name-text {

  }

  .event-filter-remove {
    background-color: $vm-border-color-one;
    float: left;
    border-top-right-radius: $eventFilterRadius;
    border-bottom-right-radius: $eventFilterRadius;
    padding: 0 5px;
    line-height: 25px;
    cursor: pointer;

    &:hover {
      color: $vm-red-color-one;
    }
  }

  .clear-filter-container {
    text-align: right;
  }

  .clear-filter-span {
    @extend .vm-link-text;
  }

  .presets-button {
    width: 100%;
    margin: 5px auto;
    text-align: center;
  }

  .input-prediction-container {

    .event-filter-option-template {

      .event-filter-display-text {

      }

      .event-filter-remaining {
        color: $vm-white-color-one;
        background-color: $vm-blue-color-one;
        padding: 0 5px;
        border-radius: 5px;
      }

      .event-filter-type {
        color: $vm-white-color-one;
        background-color: $vm-gray-color-one;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
  }

}
