#portal-reports {

  .selection-row {
    margin-bottom: 15px;
  }
}

.loadingErrorRow {
  display: table;
  width: 100%;
  height: 75px;

  font-weight: bold;
  background-color: #fbd5d5;
  color: red;
  span {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
}
