#video-action-menu-directive {

  position: relative;

  .video-action-menu-container {

  }

  .video-action-menu-item {
    float: left;
    text-align: center;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      color: $vm-primary-color-one;
    }

    &.isActive {
      color: $vm-primary-color-one;
    }
  }

  .video-action-menu-dropdown {
    position: relative;
    float: left;
    height: 50px;
  }

  .video-action-menu-icon {
    font-size: 18px;
  }

  .video-action-menu-caption {
    font-size: 10px;
  }

  .video-action-menu-caption-text {

  }


}
