.stats-match-row-2-directive {

  .match-table {
    @extend .vm-table-fixed;
    color: $vm-white-color-one;
    font-size: 14px;
    border-radius: 3px;
    font-weight: 100;
  }

  .match-row {
    @extend .vm-table-row;
  }

  .match-date-cell {
    @extend .vm-table-cell-left;
    width: 45px;
    height: 35px;
    padding: 0 5px;
  }

  .match-location-symbol-cell {
    @extend .vm-table-cell-left;
    width: 20px;
    font-weight: 800;
  }

  .match-opponent-cell {
    @extend .vm-table-cell-left;
    padding-left: 5px;
  }

  .match-score-cell {
    @extend .vm-table-cell-right;
    width: 60px;
    font-weight: 800;
    padding: 0 5px;
  }

  .score-background {
    background-color: $vm-gray-color-one;
    border-radius: 10px;
    text-align: center;
    height: 20px;
  }

  .score-text {
    color: $vm-red-color-two;
  }

  .won-match {
    color: $vm-green-color-three;
  }
}
