.match-access-match-table-row-container {
  cursor: pointer;

  .generic-table-two-row-cell-container {

  }

  .generic-table-two-row-cell-text {
    font-size: 12px;
    height: 36px;
  }

  .view-icon {
    @extend .hoverable-gray-blue-text-one;
  }

  .add-icon {
    @extend .hoverable-green-text-one;
  }
}
