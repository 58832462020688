.vm-tabs-directive {
  position: relative;

  .tab-container {
    background-color: $vm-color-white-100;
    display: inline-block;
    position: relative;
    line-height: 40px;
    padding: 0 10px;
    cursor: pointer;
    color: $vm-color-black-0;

    &:hover {
      color: $vm-primary-color-one;
    }
  }

  .tab-title {

  }

  .tab-count {
    color: $vm-color-white-100;
    background-color: $vm-color-black-0;
    padding: 0 10px;
    margin: 0 10px;
    border-radius: 10px;
  }

  .active-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
  }

  .active-tab {
    .active-bar {
      background-color: $vm-primary-color-one;
    }

    &.tab-container {
      background-color: $vm-table-header-color-one;
    }
  }
}
