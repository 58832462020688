#vm-admin-team-modal {

  .input-row {
    margin: 10px 0;
  }

  .toggle-row-container {
    border-top: 1px solid $vm-light-gray-color-one;
  }

  .toggle-row {
    position: relative;
    height: 50px;
    line-height: 50px;
  }

  .details-container {
    padding: 10px;
    background-color: $vm-light-gray-color-one;
    border-radius: 5px;
  }

  .details-title {
    @extend .vm-heading-4;
  }

  .details-row {
    position: relative;
    height: 30px;
    line-height: 30px;
  }

  .additional-actions-container {
    margin-top: 10px;
    border-top: 5px solid $vm-gray-color-one;
    padding-top: 10px;
  }

  .error-text {
    font-size: 10px;
    color: $vm-red-color-one;
    text-align: left;
  }

  .saving-error {
    font-size: 12px;
    text-align: right;
  }

  .delete-text-container {
    margin: 5px 0;
    text-align: center;
  }

  .warning-text {
    color: $vm-red-color-one;
  }

  .delete-team-container {
    background-color: $vm-table-row-color-two;
    border-radius: 5px;
    margin: 10px 10px;
    padding: 20px;
    text-align: center;
  }
}
