#vm-match-comments-directive {

  position: relative;

  .new-comment-container {

  }

  .tag-text {
    float: left;
    line-height: 35px;
    font-size: 12px;
  }

  .no-tag-text {
    color: $vm-red-color-one;
  }

  .comment-tag {
    float: left;
  }

  .new-comment-input-box {

  }

  .comment-post-message {

  }

  .comment-post-message-text {
    color: $vm-red-color-one;
  }

  .comments-heading {
    font-weight: bold;
    margin-top: 5px;
  }

  .comments-heading-text {

  }

  .vm-comment-view-container {
    margin-left: 10px;
  }

  .comment-view-text {
    @extend .hoverable-blue-text-one;
    font-size: 12px;

    &:hover {
      font-weight: 800;
    }
  }

  .posted-comment-container {
    position: relative;
    max-height: 300px;
    overflow: auto;

    &.replying {
      height: 300px;
    }
  }

  .posted-comment {

  }

  .input-prediction-container {

    .comment-taggable-template {

      .comment-taggable-category {

      }

      .comment-taggable-category {
        color: $vm-white-color-one;
        background-color: $vm-gray-color-one;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
  }

}
