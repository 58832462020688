#vm-match-video-player-directive {
  position: relative;

  .video-container {

  }

  video {
    width: 100%;
    float: left;
    max-height: 65vh;
    background-color: $vm-video-background-color-one;
  }

  .video-message {
    color: $vm-white-color-one;
    background-color: $vm-color-gray-25;
    height: 300px;
    width: 100%;
    text-align: center;
    display: table;
  }

  .video-message-text {
    font-size: 24px;
    display: table-cell;
    vertical-align: middle;
  }

  .vm-loading-video {
    padding: 100px 0;
  }

  .video-header {
    position: absolute;
    width: 100%;
    color: $vm-video-overlay-text-color-one;
    z-index: 1;
    top: 0;
  }

  .video-header-row {
    background-color: $vm-video-overlay-light-one;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: $vm-video-overlay-dark-one;
      transition: all 0.25s ease-in-out;
    }
  }

  .video-event-details-directive-container {
    float: left;
  }

  .video-scoreboard-directive-container {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 200px;
  }

  .video-action-menu-directive-container {
    float: right;
  }

  .video-footer {
    position: absolute;
    width: 100%;
    color: $vm-video-overlay-text-color-one;
    z-index: 1;
    bottom: 0;
  }

  .shortcuts-directive-container {
    float: right;
  }

  .fullscreen-events-container {
    width: 250px;
    background-color: $vm-video-overlay-light-one;
    padding: 10px;
    float: right;

    $transparentizeAmount: .25;

    .event-filter-table-directive {

      .event-filter-table {
        color: $vm-black-color-one;
        border: 1px solid transparentize($vm-border-color-one, $transparentizeAmount);
      }

      .event-filter-table-row {
        background-color: transparentize($vm-table-row-color-one, $transparentizeAmount);

        &.homeTeam {
          background-color: transparentize($vm-table-row-color-two, $transparentizeAmount - .1);
        }

        &.highlightRow {
          background-color: transparentize($vm-primary-color-one, $transparentizeAmount);
          color: $vm-white-color-one;
        }

        &:hover {
          color: $vm-black-color-one;
        }
      }
    }

    .generic-table-container {

      .generic-table-table {
        border: 1px solid transparentize($vm-border-color-one, $transparentizeAmount);
        margin-top: 10px;
      }

      .generic-table-header-group {
        background-color: transparentize($vm-table-header-color-one, $transparentizeAmount);
      }
    }

    .event-filter-selector-directive {

      .selected-event-filter-container {
        border-color: transparentize($vm-border-color-one, $transparentizeAmount);
      }

      .event-filter-name {
        background-color: transparentize($vm-primary-color-one, $transparentizeAmount);
        color: $vm-white-color-one;
      }

      .event-filter-remove {
        background-color: transparentize($vm-border-color-one, $transparentizeAmount);

        &:hover {
          color: transparentize($vm-red-color-one, $transparentizeAmount);
        }
      }
    }

    .input-box-container {
      input {
        background-color: transparentize($vm-white-color-one, 1);
        color: $vm-black-color-one;
      }

      .input-box-field {
        background-color: transparentize($vm-white-color-one, $transparentizeAmount);
        border-color: transparentize($vm-border-color-one, $transparentizeAmount);
      }
    }

  }

  .fullscreen-video-controls-panel-directive-container {
    background-color: $vm-video-overlay-light-one;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: $vm-video-overlay-dark-one;
      transition: all 0.25s ease-in-out;
    }
  }
}

#vm-match-video-player-directive:-webkit-full-screen {
  video {
    max-height: none;
    width: 100vw;

    &.taller-video {
      height: 100vh;
    }
  }
}
