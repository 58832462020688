.loading-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #37a2db;
  z-index: 199;
  opacity: 0;
  display: none;

  transition: all .25s ease;
  -moz-transition: all .25s ease;
  -webkit-transition: all .25s ease;

  &.active {
    display: block;
  }

  &.show-me {
    opacity: 1;
  }

  .spinner {
    position: absolute;
    font-size: 2em;
    color: #fff;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
}

.glyphicon.spinning {
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}

.video-loading-overlay-temp {
  background: #37a2db;
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .loading-content {
    position: absolute;
    top: 45%;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    text-align: center;

    i {
      display: block;
    }

    .text {
      font-size: 1.2em;
    }
  }

  .spinner {
    font-size: 1.4em;
    color: #fff;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
}

-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
