.input-prediction-container {
  $selectBorderRadius: 5px;
  $selectBorderSize: 0px;

  $selectTextColor: hsl(0, 0%, 97%);;
  $selectTextColor_hover: $vm-color-white-100;
  $selectBackgroundColor: hsla(0, 0%, 0%, 0.8);
  $selectBorderColor: hsla(0, 0%, 0%, 0.8);
  $selectFilterColor: hsl(82, 100%, 50%);
  $selectOptionDividerColor: hsla(0, 0%, 30%, 1);

  position: relative;

  .input-prediction-options-container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $selectBackgroundColor;
    z-index: 10;
    max-height: 200px;
    min-width: 100%;
    overflow-y: auto;
    border-radius: $selectBorderRadius;
    border: $selectBorderSize solid $selectBorderColor;
    box-shadow: 2px 2px 2px $vm-color-black-0a25;
    text-align: left;

    &.top-position {
      top: auto;
      bottom: 36px;
    }
  }

  .input-prediction-options-row {
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid $selectOptionDividerColor;
    color: $selectTextColor;

    &:hover {
      background-color: $vm-hover-color-two;
      color: $selectTextColor_hover;
    }
  }

  .input-prediction-options-template-url-row{
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid $selectOptionDividerColor;
    color: $selectTextColor;
  }

  .input-prediction-loading-row {
    padding: 5px;
    border-bottom: 1px solid $selectOptionDividerColor;
    color: grey;
  }

  .input-prediction-no-options-row {
    padding: 5px;
    border-bottom: 1px solid $selectOptionDividerColor;
    color: grey;

    &.hoverable-no-results {
      @extend .hoverable-blue-text-one;
    }
  }

  .unhighlighted-text {
    color: $selectTextColor;
  }

  .highlighted-text {
    color: $selectFilterColor;
  }

  .active-key-option {
    background-color: $vm-hover-color-two;
    color: $selectTextColor_hover;
  }

}
