.loading-directive {

  $iconSize: 40px;

  position: relative;

  .loading-directive-table {
    @extend .vm-table-fixed;
  }

  .loading-directive-row {
    @extend .vm-table-row;
  }

  .loading-directive-cell {
    @extend .vm-table-cell-center;
  }

  .outer-icon-container {
    width: $iconSize + (20px * ($iconSize / 40px));
    margin: 0 auto;
  }

  .spin-container {
    @extend .vm-spin-normal;
    font-size: $iconSize;
    color: $vm-blue-color-one;
    width: $iconSize + (20px * ($iconSize / 40px));
    height: $iconSize + (20px * ($iconSize / 40px));

    .fa-spinner::before {
      margin: 0;
    }
  }

  .loading-directive-message {
    font-size: 12px;
    color: $vm-gray-color-one;
  }

  .loading-directive-message-text {

  }

}
