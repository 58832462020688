.generic-table-modal {

  .generic-table-modal-header {
    overflow: hidden;
  }

  .generic-table-modal-body {
    overflow: hidden;
  }

  .generic-table-modal-body-table {
    display: table;
    table-layout: fixed;
    width: 100%;
    vertical-align: middle;
  }

  .generic-table-modal-body-table-row {
    display: table-row;
    width: 100%;
    height: 40px;
  }

  .generic-table-modal-body-table-header-cell {
    display: table-cell;
    width: 20%;
    text-align: right;
    padding-right: 15px;
  }

  .generic-table-modal-body-table-data-cell {
    display: table-cell;
    width: 80%;
  }

  .generic-table-modal-body-table-data-cell-input {
    width: 100%;
  }

  .generic-table-modal-footer {
    overflow: hidden;
  }

}
