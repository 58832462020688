#portal-system-administration-teams {

  .actions-header {
    margin: 10px 0;
  }

  .team-search-input {
    width: 75%;
    float: left;
  }

  .create-team-button {
    width: 20%;
    float: right;
  }
}
