#portal-header-new {
  position: relative;
  height: $headerHeight;
  width: 100%;
  background-color: $vm-primary-color-one;
  box-shadow: 0px 3px 3px $vm-color-black-0a25;

  #logo-container {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 150px;
    margin-left: 15px;
    float: left;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      filter: alpha(opacity=80);
    }
  }

  #logo-image {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  #notification-icon-notification-count {
    color: white;
    position: absolute;
    display: inline-block;
    background-color: red;
    height: 20px;
    border-radius: 10px;
    font-size: 11px;
    padding: 2px 5px;
    right: 30px;
    top: 0;
  }

  #profile-container {
    position: relative;
    float: right;
    display: table;
    height: 100%;
    min-width: 250px;
    right: 15px;
  }

  #profile-container-row {
    display: table-row;
  }

  #profile-info-cell {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    padding: 0 10px;
  }

  #notification-icon-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: white;
    font-size: 20px;
    width: 50px;
  }

  #profile-image-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: white;
    font-size: 40px;
    width: 50px;
  }

  #profile-salutation {
    color: $vm-color-white-100;
    font-weight: 800;
  }

  .selected-team-text {
    color: $vm-color-white-100;
    font-weight: 100;
    font-size: 12px;
  }

}
