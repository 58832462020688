.large-stat-card-directive {
  $poorStatColor: hsl(0, 100%, 41%);
  $goodStatColor: hsl(75, 100%, 41%);
  
  position: relative;
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;

  .card-title {

  }

  .card-title-text {
    font-weight: 800;
  }

  .card-stats-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .card-stats-table-row {
    display: table-row;
  }

  .primary-stat {
    display: table-cell;
    vertical-align: middle;
    width: 20%;
  }

  .primary-stat-text {
    font-size: 30px;
    font-weight: 800;
  }

  .secondary-stats-container {
    display: table-cell;
    vertical-align: middle;
    width: 15%;
    text-align: right;
  }

  .secondary-stat-one {

  }

  .secondary-stat-one-text {

  }

  .secondary-stat-two {

  }

  .secondary-stat-two-text {

  }

  .secondary-stats-label-container {
    display: table-cell;
    vertical-align: middle;
    width: 45%;
    padding: 0 0 0 10px;
  }

  .secondary-stat-one-label {

  }

  .secondary-stat-one-label-text {

  }

  .secondary-stat-two-label {

  }

  .secondary-stat-two-label-text {

  }

  .poor-stat {
    color: $poorStatColor;
  }

  .good-stat {
    color: $goodStatColor;
  }
}
