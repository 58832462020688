.stat-filter-card-directive {

  position: relative;
  float: left;
  padding: 1px;

  .filter-card-container {
    @extend .vm-table-cell-center;
    @extend .hoverable-light-gray-transparent-blue-background-one;
    background-color: $vm-light-gray-color-one;
    height: 35px;
    width: 32px;
    border-radius: 5px;
    padding: 1px;

    &.active {
      background-color: $vm-primary-color-one;
    }
  }

  .filter-card-label {
    font-size: 10px;
    font-weight: 800;
  }

}
