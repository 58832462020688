.vm-tooltip-directive {
  position: relative;
  width: inherit;
  height: inherit;
  display: inline-block;

  .transcluded-content {
    cursor: pointer;
  }

  .tooltip-container {
    position: absolute;
    z-index: 10;
    cursor: default;

    &.left {
      left: 0px;
      margin-left: auto;
    }

    &.right {
      right: 0px;
      margin-right: auto;
    }

    &.top {
      top: 0px;
      margin-top: auto;
    }

    &.bottom {
      bottom: 0px;
      margin-bottom: auto;
    }
  }

  .tooltip-inner-container {
    padding: 5px;
    background-color: $vm-video-overlay-dark-one;
    border-radius: 5px;
    color: white;
    box-shadow: 2px 2px 2px $vm-color-black-0a25;
  }

  .tooltip-close-container {
    text-align: right;
  }

  .tooltip-close-x {
    @extend .hoverable-gray-blue-text-one;
  }

  .tooltip-text-container {
    font-size: 11px;
    text-align: center;

    &.text-left {
      text-align: left;
    }

    &.text-center {
      text-align: center;
    }

    &.text-right {
      text-align: right;
    }
  }

  .tooltip-text {

  }
}
