.season-team-stats-tooltip {
  min-width: 300px;
  font-size: 12px;

  .season-team-stats-table {
    @extend .vm-table-fixed;
  }

  .season-team-stats-row {
    @extend .vm-table-row;
  }

  .variable-cell {
    @extend .vm-table-cell-center;
    width: 29%;
  }

  .equals-cell {
    @extend .vm-table-cell-center;
    width: 2%;
  }

  .equation-cell {
    @extend .vm-table-cell-left;
    width: 69%;
    font-size: 11px;
  }

  .numerator {
    text-align: center;
  }

  .numerator-text {

  }

  .denominator {
    text-align: center;
  }

  .denominator-text {

  }
}
