#match-access-modal {

  .margin-top-5 {
    margin-top: 5px;
  }

  .center-align-text {
    text-align: center;
  }

  .delete-text-container {
    margin: 5px 0;
    text-align: center;
  }

  .delete-match-container {
    background-color: $vm-table-row-color-two;
    border-radius: 5px;
    margin: 10px auto;
    padding: 20px;
    max-width: 500px;
  }

  .delete-vs-text {
    font-weight: 800;
    margin: 10px;
  }

  .textarea-notes-container {
    margin-top: 5px;
    max-width: 500px;
    margin: 10px auto;
    resize: none;
  }

  .textarea-notes {
    width: 100%;
    resize: none;
  }

  .error-text {
    text-align: right;
    font-size: 10px;
    color: $vm-red-color-one;
  }
}
