.system-administration-teams-table-row {

  .edit-icon {
    @extend .hoverable-gray-blue-text-one;
  }

  .off-light {
    color: $vm-gray-color-one;
  }

  .on-light {
    color: $vm-green-color-one;
  }
}
