.match-filters-section {

  position: relative;

  $matchRowHeight: 30px;

  .section-table {
    @extend .vm-table-fixed;
  }

  .section-row {
    @extend .vm-table-row;
  }

  .excluded-matches-cell {
    @extend .vm-table-cell-center;
    vertical-align: top;
    width: 53%;
    padding-right: 3px;
  }

  .scroll-container {
    height: 485px;
    overflow-y: auto;
  }

  .split-column-left {
    width: 50%;
    float: left;
    border-right: 2px solid $vm-white-color-one;
  }

  .split-column-right {
    width: 50%;
    float: right;
  }

  .match-container {
    margin: 2px 0;
  }

  .selection-control-cell {
    @extend .vm-table-cell-center;
    vertical-align: top;
    width: 20%;
    background-color: $vm-light-gray-color-two;
    border-left: 2px solid $vm-light-gray-color-one;
    border-right: 2px solid $vm-light-gray-color-one;
    padding-top: 25px;
  }

  .included-matches-cell {
    @extend .vm-table-cell-center;
    vertical-align: top;
    width: 27%;
    padding-left: 3px;
  }

  .included-matches-container {
    height: 200px;
    overflow-y: auto;
  }

  .button-group {
    background-color: $vm-light-gray-color-one;
    padding: 5px;
    margin: 5px;
  }

  .group-title {

  }

  .group-buttons {

  }
}
