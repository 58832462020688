.vm-match-event-filter-directive {

  position: relative;
  width: 100%;

  .vm-match-event-filter-container {

  }

  .vm-match-event-filter-heading {

  }

  .vm-match-event-filter-heading-text {
    font-weight: bold;
  }

  .events-header-table {
    @extend .vm-table-fixed;
    background-color: $vm-table-header-color-one;
  }

  .events-header-row {
    @extend .vm-table-row;
  }

  .events-header-cell {
    @extend .vm-table-cell-center;
    font-size: 11px;
    width: 10%;
    height: 35px;
    font-weight: 800;
  }

  .events-header-right-padding {
    @extend .vm-table-cell-center;
    width: 2%;
  }

  .no-events-message {
    color: white;
    background-color: gray;
    padding: 5px;
    text-align: center;
  }

  .no-events-message-text {

  }

}
