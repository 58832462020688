.schedule-practices-table-row-container {
  cursor: pointer;

  .edit-icon {
    @extend .hoverable-gray-blue-text-one;
  }

  .set-to-record {
    color: $vm-green-color-one;
  }

  .not-set-to-record {
    color: $vm-gray-color-one;
  }
}
