#share-modal {

  .generic-modal-body {
    padding: 30px 30px;
  }

  .share-container {

  }

  .share-header {

  }

  .share-match-with-text {
    @extend .vm-heading-4;
  }

  .share-by-link-text {
    @extend .vm-link-text;
    float: right;
  }

  .download-link-input {
    width: 80%;
    float: left;
    font-size: 11px;
    height: 34px;
  }

  .copy-link-button {
    width: 20%;
    float: right;
  }

  .toggle-share-type-text {
    @extend .vm-link-text;
    float: right;
  }

  .share-by-email-container {

  }

  .share-email-input {

  }

  .share-by-team-container {

  }

  .share-level-select {
    margin-bottom: 5px;
  }

  .share-gender-select {
    margin-bottom: 5px;
  }

  .share-team-select {

  }

  .sharing-with-teams-container {

  }

  .sharing-with-team-text {
    float: left;
    line-height: 35px;
    font-size: 12px;
  }

  .sharing-with-team-tag {
    float: left;
  }

  .shared-container {

  }

  .shared-type-container {
    @extend .vm-table-row;
  }

  .shared-icon {
    @extend .vm-table-cell-center;
    vertical-align: top;
    font-size: 50px;
  }

  .shared-message-container {
    @extend .vm-table-cell-left;
  }

  .shared-link-text {
    margin-top: 10px;
  }
}
