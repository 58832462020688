.close-comments-directive {

  position: relative;
  color: $vm-white-color-one;

  .close-comments-container {
    background-color: hsla(0, 0%, 0%, 0.4);
    padding: 0px 10px;
  }

  .close-comment {
    font-size: 12px;
    padding: 2px 0;
    font-weight: 800;
    border-bottom: 1px solid $vm-transparent-gray-color-one;
  }

  .close-comment-signature-container {
    float: left;
    width: 100%;
  }

  .close-comment-body-container {
    float: left;
    width: 70%;
  }

  .close-comment-action-container {
    float: left;
    width: 20%;
  }

  .close-comment-timestamp {
    font-weight: 100;
  }

  .close-comment-name {
    font-weight: 100;
  }

  .close-comment-body {

  }

  .close-comment-action-text {
    @extend .hoverable-gray-blue-text-one;
    color: $vm-light-gray-color-one;
    font-weight: 100;
    font-size: 10px;
  }

  .close-comments-system-message-container {
    font-weight: 100;
    font-size: 10px;
    color: gray;
    border-top: 1px solid $vm-transparent-gray-color-one;
  }

  .close-comments-system-message-container-text {
    float: left;
  }

  .toggle-comment-console-text-group {
    @extend .hoverable-gray-blue-text-one;
    color: $vm-light-gray-color-one;
    font-weight: 100;
    font-size: 10px;
    float: right;
  }

  .toggle-comment-console-text {

  }

  .new-comment-container {
    color: rgba(0, 0, 0, .54);
    padding-bottom: 5px;
  }

  .tagged-container {

  }

  .tag-text {
    float: left;
    line-height: 35px;
    font-size: 12px;
  }

  .no-tag-text {
    color: $vm-red-color-one;
  }

  .comment-tag {
    float: left;
  }

  .highlight-close-comment {
    color: $vm-green-color-one;
  }

  .input-prediction-container {

    .comment-taggable-template {

      .comment-taggable-category {

      }

      .comment-taggable-category {
        color: $vm-white-color-one;
        background-color: $vm-gray-color-one;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
  }
}
