.shortcuts-container {
  display: inline-block;
  min-width: 175px;
  background-color: hsla(0, 0%, 0%, 0.50);
  color: white;

  .shortcut-container {
    padding: 2px 10px;
  }

  .shortcut-key {
    float: right;
    width: 20%;
    border-radius: 5px;
    border: 1px solid white;
    text-align: center;
    line-height: 16px;
  }

  .shortcut-key-text {

  }

  .shortcut-label {
    float: right;
    padding-right: 10px;
    text-align: right;
    display: inline-block;
    width: 80%;
    font-size: 11px;
    line-height: 16px;
  }

  .shortcut-label-text {

  }
}
