.volume-control-container {
  padding: 10px 10px 5px;
  cursor: pointer;

  .volume-bar-container {

  }

  .volume-bar-background {
    position: relative;
    height: 100px;
    width: 100%;
    background-color: $vm-primary-color-one;
  }

  .volume-bar-foreground {
    position: absolute;
    width: inherit;
    background-color: gray;
  }

  .volume-icon {
    margin-top: 10px;
    text-align: center;
    padding: 5px 0;

    &:hover {
      color: $vm-primary-color-one;
    }
  }
}
