.match-access-search-matches-section {

  .bulk-match-id-textarea {
    width: 79%;
    resize: none;
    float: left;
  }

  .apply-button {
    width: 20%;
    float: right;
  }
}
