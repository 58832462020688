.accept-account-link-invite-view {

  .link-message {
    text-align: center;
  }

  .success-icon {
    color: hsl(87, 82%, 50%);
    font-size: 35px;
    text-align: center;
  }

  .error-icon {
    color: red;
    font-size: 35px;
    text-align: center;
  }

}
