.match-access-search-entities-section {

  .entity-half-left-button {
    float: left;
    width: 49%;
  }

  .entity-half-right-button {
    float: right;
    width: 49%;
  }

  .entity-full-button {
    float: right;
    width: 100%;
  }
}
