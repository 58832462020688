#conferences {
  margin-top: 15px;
  
  #searchInput {
    margin-left: 10px;
  }

  #conferenceTable {
    margin-top: 15px;
    td {
      text-align: left;
    }
    th {
      text-align: left;
      cursor: pointer;
      i {
        margin-left: 10px;
      }
      &:hover {
        color: $blue;
      }
    }
  }

  .conferenceRow {
    cursor: pointer;
    &:hover {
      background-color: $blue;
    }
  }
}
