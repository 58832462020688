#schedule-modal {

  .generic-modal-body {
    padding: 15px 30px;
  }

  .modal-table-one-third {
    @extend .vm-table-fixed;
    width: 33%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .modal-table-one-half {
    @extend .vm-table-fixed;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .modal-table-full {
    @extend .vm-table-fixed;
    margin-bottom: 20px;
  }

  .modal-row {
    @extend .vm-table-row;
  }

  .modal-cell {
    @extend .vm-table-cell-center;
  }

  .match-type-cell {

  }

  .vs-cell {
    padding-top: 10px;
    width: 50px;
    font-weight: 800;
    font-size: 20px;
  }

  .date-row-cell {
    padding: 0 5px;
  }

  .input-title {
    font-weight: 800;
  }

  .time-value {
    float: left;
    width: 70%;
  }

  .time-timezone {
    display: inline-block;
    float: left;
    width: 30%;
    line-height: 35px;
  }

  .record-switch {
    height: 50px;
  }

  .delete-text-container {
    margin: 5px 0;
    text-align: center;
  }

  .delete-match-container {
    background-color: $vm-table-row-color-two;
    border-radius: 5px;
    margin: 10px 10px;
    padding: 20px;
  }

  .delete-vs-text {
    font-weight: 800;
    margin: 10px;
  }

  .warning-text {
    color: $vm-red-color-one;
  }

  .error-text {
    font-size: 10px;
    color: $vm-red-color-one;
    text-align: left;
  }

  .suggest-team-link-container {
    text-align: right;
    margin-top: 20px;
  }

  .suggest-team-link {
    @extend .vm-link-text;
    font-size: 12px;
  }

  .saving-error {
    font-size: 12px;
    text-align: right;
  }

  .suggest-team-title {
    @extend .vm-heading-3;
  }

  .suggest-team-input-container {
    background-color: $vm-table-row-color-two;
    border-radius: 5px;
    margin: 10px 10px;
    padding: 20px;
  }

  .suggest-team-input-box {
    margin-bottom: 10px;
  }

  .duplicate-event-subtitle {
    @extend .vm-heading-4;
  }

  .duplicate-event-introduction-text {

  }

  .duplicate-events-container {
    background-color: $vm-light-gray-color-one;
  }

  .duplicate-event {

  }

  .duplicate-event-conclusion-text {

  }

  .venue-overlap-warning-container {
    background-color: $vm-light-gray-color-one;
  }

  .venue-option-template {

  }

  .venue-option-name {

  }

  .venue-option-title {
    text-align: center;
    background-color: $vm-white-color-one;
    color: $vm-black-color-one;
  }


  .venue-option-team {
    font-size: 10px;
    color: $vm-gray-color-one;
  }
}
