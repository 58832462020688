#portal-dashboard {

  position: relative;

  .column1 {
    float: left;
    width: 70%;
    min-width: 400px;
    padding-right: 10px;
    @media (max-width: $mediumBreakpoint) {
      float: none;
      width: 100%;
      padding-right: 0;
    }
  }

  .column2 {
    float: left;
    width: 30%;
    min-width: 200px;
    @media (max-width: $mediumBreakpoint) {
      float: none;
      width: 100%;
      padding-top: 10px;
    }
  }

  .quick-tip-directive-container {
    max-width: 400px;
    float: right;
  }

  .top-stats-directive-container {
    @media (min-width: $mediumBreakpoint) {
      margin-top: 35px;
    }
  }

  .sandbox-account-container {
    
  }

  .sandbox-div {
    margin-top: 10px;
  }

  .sandbox-heading-1 {
    @extend .vm-heading-1;
    color: $vm-primary-color-one;
  }

  .sandbox-heading-2 {
    @extend .vm-heading-3;
  }

  .sandbox-paragraph {

  }
}
