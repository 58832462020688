#conferenceModal {

  .required-warning {
    color: red;
    font-size: 10px;
  }

  .status-dot {
    margin-right: 3px;
  }

  .active-status {
    color: lawngreen;
  }

  .future-status {
    color: yellow;
  }

  .inactive-status {
    color: dimgray;
  }

  .modal-header {
    height: 50px;
    h3 {
      margin-top: 0px;
    }
  }

  #conference-delete-btn {
    float: left;
  }

  #conferenceForm {
    padding: 15px;

    .season-btn {
      transition: transform .1s;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      }

      &:focus {
        outline: 0;
      }

      &.active {
        transform: scale(1.1);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
      }
    }

    .confExchangeCheckbox {
      margin-left: 30px;
    }

    .better-checkbox {
      @extend %better-checkbox-mix
    }

    #conference-seasons {
      padding: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      
      #conference-season-info {
        margin-top: 10px;
      }
      #add-edit-conference-season {
        width: 100%;
        margin-top: 10px;

        #edit-add-btn {
          display: flex;
          justify-content: center;
        }

      }
    }

    #season-info {
      display: flex;
      width: 300px;
      justify-content: space-evenly;
      dl {
        font-size: 11px;
      }
    }
  }
}

%better-checkbox-mix {

  $md-checkbox-margin: 16px 0;
  $md-checkbox-checked-color: rgb(51, 122, 183);
  $md-checkbox-border-color: rgba(0, 0, 0, 0.54);
  $md-checkbox-size: 20px;
  $md-checkbox-padding: 4px;
  $md-checkmark-width: 2px;
  $md-checkmark-color: #fff;

  position: relative;
  margin: $md-checkbox-margin;

  label {
    cursor: pointer;
    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: 2px solid $md-checkbox-border-color;
      border-radius: 2px;
      cursor: pointer;
      transition: background .3s;
    }

    &:after {
      // checkmark
    }
  }

  input[type="checkbox"] {
    outline: 0;
    margin-right: $md-checkbox-size - 10px;

    &:checked {
      + label:before {
        background: $md-checkbox-checked-color;
        border: none;
      }
      + label:after {

        $md-checkmark-size: $md-checkbox-size - 2*$md-checkbox-padding;

        transform: rotate(-45deg);

        top: ($md-checkbox-size / 2) - ($md-checkmark-size / 4) - $md-checkbox-size/10;
        left: $md-checkbox-padding;
        width: $md-checkmark-size;
        height: $md-checkmark-size / 2;

        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
      }
    }
  }

}