.portal-notifications-directive {

  position: relative;

  .notifications-container {

  }

  .notification-row {
    border-bottom: 1px solid gray;
    text-align: left;
    padding: 5px 10px;
    font-size: 11px;
    color: gray;
  }

  .notification-message {

  }

  .notification-message-text {

  }

  .notification-summary {
    font-size: 12px;
    font-weight: 800;
    color: white;
  }

  .notification-summary-text {

  }

  .notification-footer {

  }

  .notification-conversation {
    @extend .hoverable-blue-text-one;
    float: left;
  }

  .notification-moment {
    float: right;
  }

  .no-notifications-text {
    font-size: 12px;
  }

  .view-all-button-container {
    text-align: left;
    padding: 5px;
  }

}
