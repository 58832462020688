.event-filter-table-directive {
  .event-filter-column {
    @extend .vm-table-cell-center;
    border-bottom: 1px solid $vm-border-color-one;
  }

  .event-filter-column1 {

  }

  .event-filter-column2 {

  }

  .event-filter-column3 {

  }


}
