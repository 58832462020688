#portal-schedule {

  .actions-container {
    padding-bottom: 10px;
  }

  .portal-matches-search-box-cell {
    @extend .vm-table-cell;
    width: 40%;
  }

  .portal-matches-date-filter-cell {
    @extend .vm-table-cell;
    width: 25%;
    padding-left: 5px;
  }

  .portal-matches-search-button-cell {
    @extend .vm-table-cell;
    width: 10%;
    padding-left: 5px;
  }

  .search-button {
    width: 100%;
  }

  .create-new-button {
    position: relative;
    float: right;
    z-index: 1;

    .loading-teams {
      display: inline-block;
      padding: 7px;
      margin-right: 10px;
    }
  }
}
