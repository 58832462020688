#portal-matches {

  #portal-matches-search-container {
    display: table;
    width: 100%;
  }

  #portal-matches-search-row {
    display: table-row;
  }

  #portal-matches-search-box-cell {
    display: table-cell;
    width: 60%;
  }

	#portal-matches-season-filter-cell {
		display: table-cell;
		width: 25%;
		padding-left: 15px;
		padding-right: 15px;
	}

  #portal-matches-advanced-filters-cell {
    display: table-cell;
    width: 15%;
    vertical-align: middle;
    text-align: right;
    padding-left: 15px;
    padding-right: 15px;
  }

  #portal-matches-advanced-filters-cell {
    @extend .hoverable-blue-text-one;
  }

  #portal-matches-advanced-filters-container {
    display: block;
    background-color: $vm-secondary-color-one;
    margin-top: 10px;
    padding: 10px;
  }

  #portal-matches-tabs {
    margin-top: 10px;
  }

  .filters-container {
    margin-top: 10px;
  }

  .filter-container {
    display: inline-block;
    width: 215px;
    float: left;
    margin-left: 3px;
    margin-right: 3px;
  }

  .clear-filters-button {
    margin-right: 5px;
  }

	.invisible {
		visibility: hidden;
	}
}
