.share-download-page {

  .match-details-container {
    text-align: center;
    margin: 5px;
    padding: 5px;
    background-color: $vm-light-gray-color-one;
    border-radius: 5px;
  }

  .match-details-team-text {
    font-weight: 800;
    font-size: 20px;
  }

  .match-details-text {

  }

  .match-details-date-text {
    margin-top: 10px;
    font-weight: 800;
  }
}
