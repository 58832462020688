.event-filter-table-directive {
  position: relative;

  .event-filter-table-container {
    max-height: 300px;
    overflow: auto;
  }

  .event-filter-table {
    @extend .vm-table-fixed;
    border: 1px solid $vm-border-color-one;
  }

  .event-filter-table-row {
    @extend .vm-table-row;
    height: 36px;
    cursor: pointer;
    font-size: 12px;
    background-color: $vm-table-row-color-one;

    &.homeTeam {
      background-color: $vm-table-row-color-two;
    }

    &.highlightRow {
      background-color: $vm-primary-color-one;
      color: $vm-white-color-one;
    }

    &:hover {
      font-weight: 800;
      font-size: 13px;
      color: $vm-black-color-one;
    }
  }

  .loading-more {

  }

  .loading-more-text {

  }
}
