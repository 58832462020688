.top-stats-directive {
  $titleColor: rgb(0, 0, 0);
  $borderRadius: 5px;

  position: relative;
  border-radius: $borderRadius;
  border: 1px solid $vm-border-color-one;

  .title-container {
    color: $titleColor;
    text-align: center;
    background-color: $vm-table-header-color-one;
    line-height: 25px;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
  }

  .title-text {

  }

  .team-selector-container {

  }

  .team-selector {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }

  .error-message-container {
    color: red;
    text-align: center;
  }

  .error-message-text {

  }

  .content-container {

  }

  .overall-container {

  }

  hr {
    margin: 10px 20px;
  }

  .skills-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .skills-row {
    display: table-row;
    height: 100px;
  }

  .skills-cell {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
  }

  .view-more-stats-container {
    padding: 10px;
  }

  .view-more-stats {
    @extend .vm-link-text;
    float: right;
  }

  .view-more-stats-text {

  }
}
