.video-controls-panel-directive {

  position: relative;

  .video-footer-controls {

  }

  .controls-row {

  }

  .left-controls {
    float: left;

  }

  .right-controls {
    float: right;
  }

  .control-icon {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    width: 35px;
    padding-bottom: 5px;
    float: left;

    &:hover {
      color: $vm-primary-color-one;
    }

    &.disabled {
      color: gray;
    }

    &.isActive {
      color: $vm-primary-color-one;
    }
  }

  .control-callout {
    float: left;
  }

  .time-text {
    color: gray;
    font-weight: 100;
  }

  .video-seek-row {
    padding: 10px 10px 5px;
  }

  .seek-bar-background {
    position: relative;
    height: 3px;
    background-color: gray;
    cursor: pointer;

    &:hover {

    }
  }

  .seek-bar-foreground {
    position: absolute;
    height: inherit;
    background-color: $vm-primary-color-one;
  }

  .seek-bar-comment-point {
    position: absolute;
    width: 0;
    height: 0;
    border-width: 6px 6px 0 0;
    border-color: red transparent transparent transparent;
    border-style: solid;
    top: -9px;
    bottom: 0;
    margin: auto 0;

    &:hover {
        border-color: white transparent transparent transparent;
      z-index: 1;
    }
  }
}
