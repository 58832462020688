.light_gray {
  background-color: #f6f6f6;
}
.blue {
  background-color: #36a1da;
}
.white {
  background-color: #ffffff;
}
.red {
  background-color: #d8534f;
}
.charcoal {
  background-color: #474747;
  color: #ffffff;
}
.charcoal_light {
  background-color: #5c5b5b;
  color: #ffffff;
}
.new-blue {
  background-color: #0071a2;
  color: rgba(255, 255, 255, 0.75);
}

.banded_rows {
  &:nth-child(2n+1) {
    background-color: #ececec;
  }
}

.hover-highlight {
  cursor: pointer;
  &:hover {
    background-color: #36a1da;
    color: white;
  }
}

.callout-highlight {
  cursor: pointer;
  &:hover {
    background-color: #0276b4;
    color: white;
  }
}


.highlightedItem {
  background-color:  #36a1da;
}

.highlightedTitle {
  background-color:  #225f80;
}


/*borders*/
.border-white-thick {
  border: 2px solid #ffffff;
}
.noborder {
  border: 0px;
}
/*fonts*/
/*color*/
.text-white {
  color: #ffffff;
}
.text-darker-blue {
  color: #1e76a5;
}
.text-blue {
  color: #36a1da;
}
.text-black54 {
  color: rgba(0, 0, 0, .54);
}
.text-black26 {
  color: rgba(0, 0, 0, .26);
}
.text-green {
  color: #80c569;
}
.text-red {
  color: #d9534f;
}
.text-yellow {
  color: #febf32;
}
.text-orange {
  color: #fe9431;
}
/*typogrophy*/
h1 {
  font-weight: 300;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.thin-font {
  font-weight: 300;
}
.regular-font {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}
/*corners*/
.big-rounded-corners {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.rounded-corners {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
/*display*/
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.hide {
  display: none;
}
.table {
  display: table;
}
.table-cell {
  display: table-cell;
}
/*positioning*/
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
/*float*/
.left {
  float: left;
}
.right {
  float: right;
}
.nofloat {
  float: none;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix {
  zoom: 1;
}
/* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
}
/* IE7 */
/*centering*/
.center {
  margin-left: auto;
  margin-right: auto;
}
/*padding*/
.nopad {
  padding: 0px;
}
.pad5 {
  padding: 5px;
}
.pad10 {
  padding: 10px;
}
.pad15 {
  padding: 15px;
}
.pad20 {
  padding: 20px;
}
.pad30 {
  padding: 30px;
}

.nopad-left {
  padding-left: 0px;
}
.pad5-left {
  padding-left: 5px;
}
.pad10-left {
  padding-left: 10px;
}
.pad15-left {
  padding-left: 15px;
}
.pad20-left {
  padding-left: 20px;
}
.pad30-left {
  padding-left: 30px;
}

.nopad-top {
  padding-top: 0px;
}
.pad5-top {
  padding-top: 5px;
}
.pad10-top {
  padding-top: 10px;
}
.pad15-top {
  padding-top: 15px;
}
.pad20-top {
  padding-top: 20px;
}
.pad30-top {
  padding-top: 30px;
}

.nopad-right {
  padding-right: 0px;
}
.pad5-right {
  padding-right: 5px;
}
.pad10-right {
  padding-right: 10px;
}
.pad15-right {
  padding-right: 15px;
}
.pad20-right {
  padding-right: 20px;
}
.pad30-right {
  padding-right: 30px;
}

.nopad-bottom {
  padding-bottom: 0px;
}
.pad5-bottom {
  padding-bottom: 5px;
}
.pad10-bottom {
  padding-bottom: 10px;
}
.pad15-bottom {
  padding-bottom: 15px;
}
.pad20-bottom {
  padding-bottom: 20px;
}
.pad30-bottom {
  padding-bottom: 30px;
}
/*margin*/
.nomargin {
  margin: 0px;
}
.margin5 {
  margin: 5px;
}
.margin10 {
  margin: 10px;
}
.margin15 {
  margin: 15px;
}
.margin20 {
  margin: 20px;
}
.margin30 {
  margin: 30px;
}

.nomargin-left {
  margin-left: 0px;
}
.margin5-left {
  margin-left: 5px;
}
.margin10-left {
  margin-left: 10px;
}
.margin15-left {
  margin-left: 15px;
}
.margin20-left {
  margin-left: 20px;
}
.margin30-left {
  margin-left: 30px;
}

.nomargin-top {
  margin-top: 0px;
}
.margin5-top {
  margin-top: 5px;
}
.margin10-top {
  margin-top: 10px;
}
.margin15-top {
  margin-top: 15px;
}
.margin20-top {
  margin-top: 20px;
}
.margin30-top {
  margin-top: 30px;
}

.nomargin-right {
  margin-right: 0px;
}
.margin5-right {
  margin-right: 5px;
}
.margin10-right {
  margin-right: 10px;
}
.margin15-right {
  margin-right: 15px;
}
.margin20-right {
  margin-right: 20px;
}
.margin30-right {
  margin-right: 30px;
}

.nomargin-bottom {
  margin-bottom: 0px;
}
.margin5-bottom {
  margin-bottom: 5px;
}
.margin10-bottom {
  margin-bottom: 10px;
}
.margin15-bottom {
  margin-bottom: 15px;
}
.margin20-bottom {
  margin-bottom: 20px;
}
.margin30-bottom {
  margin-bottom: 30px;
}
/*widths / heights*/
.wide100 {
  width: 100%;
}
/*cursor*/
.pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.cursor-moveLeftRight {
  cursor: ew-resize;
}

/*full back*/
.full-back {
  @extend .fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

/*action*/
.clickable-blue {
  &:hover {
    color: darken(hsl(200, 80%, 50%), 10%);
  }
  &:active {
    color: darken(hsl(200, 80%, 50%), 15%);
  }
  @extend .pointer;
}
.clickable-red {
  &:hover {
    color: darken(hsl(0, 74%, 50%), 10%);
  }
  &:active {
    color: darken(hsl(0, 74%, 50%), 15%);
  }
  @extend .pointer;
}
.clickable-green {
  &:hover {
    color: darken(hsl(90, 80%, 50%), 10%);
  }
  &:active {
    color: darken(hsl(90, 80%, 50%), 15%);
  }
  @extend .pointer;
}
.clickable-white {
  &:hover {
    color: rgba(255, 255, 255, .7);
  }
  &:active {
    color: rgba(255, 255, 255, .5);
  }
  @extend .pointer;
}
.clickable-pure-white {
  &:hover {
    color: rgb(255, 255, 255);
  }
  &:active {
    color: rgba(255, 255, 255, .9);
  }
  @extend .pointer;
}
.clickable-black {
  &:hover {
    color: rgba(0, 0, 0, .75);
  }
  &:active {
    color: rgba(0, 0, 0, .85);
  }
  @extend .pointer;
}
.clickable-red-back {
  &:hover {
    background-color: darken(hsl(0, 74%, 50%), 10%);
  }
  &:active {
    background-color: darken(hsl(0, 74%, 50%), 15%);
  }
  @extend .pointer;
}
.clickable-important {
  background-color: rgb(165, 165, 165);
  &:hover {
    background-color: rgb(165, 0, 0);
  }
  &:active {
    background-color: rgb(130, 0, 0);
  }
  @extend .pointer;
}

/*tooltip*/
.tooltip {
  position: absolute;
  z-index: 1020;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: .9;
  filter: alpha(opacity=90);
}
.tooltip.top {
  margin-top: -2px;
}
.tooltip.right {
  margin-left: 2px;
}
.tooltip.bottom {
  margin-top: 2px;
}
.tooltip.left {
  margin-left: -2px;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #474747;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #474747;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #474747;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #474747;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #474747;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 10px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
/*table*/
.table {
  border-collapse: separate;
  thead {
    tr {
      th {
        @extend .text-center;
        vertical-align: middle;
        border: 0px;
        .md.md-expand-more {
          @extend .margin5-left;
          font-size: 12px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        @extend .text-center;
        vertical-align: middle;
        border-bottom: 1px solid #ddd;
        border-top: 0px;
      }
    }
  }
}

.valign {
  vertical-align: middle;
}

/*on-off*/
.onoffswitch {
  position: relative;
  width: 55px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0s ease-in 0.3s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0s ease-in 0.3s;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "Yes ";
  padding-left: 10px;
  background-color: #80C569;
  color: #FFFFFF;
}
.onoffswitch-inner:after {
  content: "No";
  padding-right: 10px;
  background-color: #d8534f;
  color: #ffffff;
  text-align: right;
}
.onoffswitch-switch {
  border: 2px solid #80C569;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0px;
  background: #FFFFFF;
  border-radius: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 35px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
.onoffswitch-label.off {
  .onoffswitch-switch {
    border: 2px solid #d8534f;

  }
}
.onoffswitch.disabled.off {
  .onoffswitch-inner {
    margin-left: -100%;
  }
  .onoffswitch-inner:before {
    background-color: #d2d2d2;
  }
  .onoffswitch-inner:after {
    background-color: #d2d2d2;
  }
  .onoffswitch-switch {
    border: 2px solid #d2d2d2;
    right: 35px;
  }
}

.onoffswitch.disabled.on {
  .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-inner:before {
    background-color: #d2d2d2;
  }
  .onoffswitch-inner:after {
    background-color: #d2d2d2;
  }
  .onoffswitch-switch {
    border: 2px solid #d2d2d2;
    right: 0px;
  }
}

.boxed-in {
  margin-bottom: -2px;
}
/*select*/
select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  @extend .pointer;
}
@media (min-width: 768px) {
  #advanced-search-modal .modal-dialog {
    width: 500px;
    margin: 30px auto;
  }
}

ul.nav.nav-tabs {
  margin-bottom: 20px;
}
@font-face {
  font-family: 'md-icons';
  src: url('fonts/Material-Design-Iconic-Font.eot?v=1.0.1');
  src: url('fonts/Material-Design-Iconic-Font.eot?#iefix&v=1.0.1') format('embedded-opentype'), url('fonts/Material-Design-Iconic-Font.woff?v=1.0.1') format('woff'), url('fonts/Material-Design-Iconic-Font.ttf?v=1.0.1') format('truetype'), url('fonts/Material-Design-Iconic-Font.svg?v=1.0.1#Material-Design-Iconic-Font') format('svg');
  font-weight: normal;
  font-style: normal;
}

.suto-table-responsive {
	.suto-table {
		width:100%;
		font-weight: bold;
		font-size: 14px;
		display: table;
		margin-bottom: 0px;
		text-align: center;
		.th {
			display: table-header-group;
		}
		.tr {
			display: table-row;
			.table-cell {
				white-space: nowrap;
				padding:8px;
				min-height:36px;
				min-width: 30px;
				border-bottom: 1px solid #dddddd;
				i.md-expand-more {
					margin-left:5px;
					font-size: 12px;
					font-weight:400;
				}
			}
			&.deactivated {
				background-color: #eaeaea;
				&:hover {
					background-color: #dbdbdb;
				}
			}
		}
		.tb {
			display:table-row-group;
			.tr:last-child {
				.table-cell {
					border: 0px;
				}
			}
		}
	}
}

.suto-table-responsive .suto-table .tr .table-cell.ng-binding, .portal-modal .suto-table-responsive .suto-table .tr .table-cell.ng-binding {
  overflow: hidden;
  text-overflow: ellipsis;
}

.suto-table {
  table-layout: fixed;
}

.suto-table-responsive {
  overflow: visible;
}

.font-size13 {
  font-size: 13px;
}

.font-size22 {
  font-size: 22px;
}
