.filter-blur-min {
  filter: blur(5px);
}

.filter-blur-medium {
  filter: blur(10px);
}

.filter-blur-max {
  filter: blur(15px);
}

.filter-grayscale-100 {
  filter: grayscale(100%);
}

.hoverable-green-text-one {
  color: $vm-green-color-one;
  cursor: pointer;
  &:hover {
    color: darken($vm-green-color-one, 10%);
  }
}

.hoverable-red-text-one {
  color: $vm-red-color-one;
  cursor: pointer;
  &:hover {
    color: darken($vm-red-color-one, 10%);
  }
}

.hoverable-blue-text-one {
  color: $vm-blue-color-one;
  cursor: pointer;
  &:hover {
    color: darken($vm-blue-color-one, 10%);
  }
}

.hoverable-gray-blue-text-one {
  color: $vm-gray-color-one;
  cursor: pointer;
  &:hover {
    color: $vm-blue-color-one;
  }
}

.hoverable-gray-red-text-one {
  color: $vm-gray-color-one;
  cursor: pointer;
  &:hover {
    color: $vm-red-color-one;
  }
}

.hoverable-white-text-one {
  color: darken($vm-white-color-one, 5%);
  cursor: pointer;
  &:hover {
    color: $vm-white-color-one;
  }
}

.hoverable-blue-background-one {
  background-color: $vm-blue-color-one;
  cursor: pointer;
  &:hover {
    background-color: darken($vm-blue-color-one, 10%);
  }
}

.hoverable-green-background-one {
  background-color: $vm-green-color-one;
  cursor: pointer;
  &:hover {
    background-color: darken($vm-green-color-one, 10%);
  }
}

.hoverable-light-gray-transparent-blue-background-one {
  background-color: $vm-light-gray-color-one;
  cursor: pointer;
  &:hover {
    background-color: $vm-transparent-blue-color-one;
  }
}

.vm-table {
  display: table;
  width: 100%;
}

.vm-table-fixed {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.vm-table-row {
  display: table-row;
  width: 100%;
}

.vm-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.vm-table-cell-center {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.vm-table-cell-left {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.vm-table-cell-right {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}

.vm-heading-1 {
  font-weight: 800;
  font-size: 22px;
}

.vm-heading-2 {
  font-weight: 800;
  font-size: 20px;
}

.vm-heading-3 {
  font-weight: 800;
  font-size: 18px;
}

.vm-heading-4 {
  font-weight: 800;
  font-size: 16px;
}

.vm-heading-5 {
  font-weight: 800;
  font-size: 14px;
}

.vm-heading-6 {
  font-weight: 800;
  font-size: 12px;
}

.vm-link-text {
  text-decoration: none;
  @extend .hoverable-blue-text-one;
}

@keyframes rotate-360 {
  100% {
    transform: rotate(360deg);
  }
}

.vm-spin-slow {
  animation: rotate-360 2.5s linear infinite;
}

.vm-spin-normal {
  animation: rotate-360 1.5s linear infinite;
}

.vm-spin-fast {
  animation: rotate-360 0.5s linear infinite;
}

.generic-modal {
  position: relative;
  padding: 10px;

  .generic-modal-container {
    position: relative;
  }

  .generic-modal-header {
    @extend .vm-heading-3;
    position: relative;
    border-bottom: 1px solid $vm-border-color-one;
    padding-bottom: 10px;
  }

  .generic-modal-cancel {
    position: absolute;
    top: 0;
    right: 0;
    @extend .hoverable-gray-red-text-one;
  }

  .generic-modal-body {
    position: relative;
    padding: 10px 0;
  }

  .generic-modal-footer {
    position: relative;
    border-top: 1px solid $vm-border-color-one;
    padding-top: 10px;
  }

  .generic-modal-footer-button-container {
    float: right;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.bold {
  font-weight: 800;
}

.thin-hr {
  margin: 5px 0;
}

.small-shadow {
  box-shadow: 2px 2px 5px $vm-modal-backdrop-color;
}

.medium-shadow {
  box-shadow: 4px 4px 5px $vm-modal-backdrop-color;
}

.large-shadow {
  box-shadow: 6px 6px 5px $vm-modal-backdrop-color;
}

// modal dialog style overrides
.modal-dialog {

}

.modal-content {
  border-radius: 0;
  border: 0 solid white;
  @extend .small-shadow;
}

div.modal-x-large {
  @media (min-width: 768px) {
    width: 900px;
  }
}

div.modal-xx-large {
  @media (min-width: 768px) {
    width: 90%;
  }
  @media (min-width: 1024px) {
    width: 90%;
  }
}

.alert-success-notice {
  animation-name: alert-success-notice;
  animation-delay: 0s;
  animation-iteration-count: 16;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes alert-success-notice {
  from {
    background-color: hsl(102, 44%, 89%);
  }
  to {
    background-color: darken(hsl(102, 44%, 89%), 20%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes hide-me {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@for $px from 0 through 100 {
  .margin-#{$px} {
    margin: $px;
  }
}

@for $px from 0 through 100 {
  .margin-top-#{$px} {
    margin-top: $px;
  }
}

@for $px from 0 through 100 {
  .margin-bottom-#{$px} {
    margin-bottom: $px;
  }
}

@for $px from 0 through 100 {
  .margin-left-#{$px} {
    margin-left: $px;
  }
}

@for $px from 0 through 100 {
  .margin-right-#{$px} {
    margin-right: $px;
  }
}

@for $px from 0 through 100 {
  .padding-#{$px} {
    padding: $px;
  }
}

@for $px from 0 through 100 {
  .padding-top-#{$px} {
    padding-top: $px;
  }
}

@for $px from 0 through 100 {
  .padding-bottom-#{$px} {
    padding-bottom: $px;
  }
}

@for $px from 0 through 100 {
  .padding-left-#{$px} {
    padding-left: $px;
  }
}

@for $px from 0 through 100 {
  .padding-right-#{$px} {
    padding-right: $px;
  }
}
