#left-menu-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $vm-secondary-color-one;
  box-shadow: 2px 0px 2px $vm-color-black-0a25;
  color: $vm-color-black-0;

  .left-menu-button-container-top {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
  }

  .left-menu-button-container-bottom {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  .left-menu-button-relative {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .left-menu-button {
    display: table-row;
    color: $vm-black-color-one;
    width: 100%;
    height: 60px;
    cursor: pointer;

    &:hover {
      color: $vm-primary-color-one;
      text-decoration: none;
    }
  }

  .left-menu-active-bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
  }

  .active-button-blue {
    background-color: $vm-primary-color-one;
  }

  .left-menu-description-container {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .left-menu-icon {
    font-size: 21px;
  }

  .left-menu-text {
    font-size: 12px;
    padding: 0 7px;
  }
}
