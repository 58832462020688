// Color definitions
$vm-color-white-100: hsla(0, 0%, 100%, 1);
$vm-color-white-100a75: hsla(0, 0%, 100%, .75);
$vm-color-white-100a50: hsla(0, 0%, 100%, .50);
$vm-color-white-100a25: hsla(0, 0%, 100%, .25);

$vm-color-gray-light-98: hsla(0, 0%, 98%, 1);
$vm-color-gray-light-96: hsla(0, 0%, 96%, 1);
$vm-color-gray-light-94: hsla(0, 0%, 94%, 1);
$vm-color-gray-light-92: hsla(0, 0%, 92%, 1);
$vm-color-gray-light-90: hsla(0, 0%, 90%, 1);
$vm-color-gray-light-88: hsla(0, 0%, 88%, 1);
$vm-color-gray-light-86: hsla(0, 0%, 86%, 1);
$vm-color-gray-light-84: hsla(0, 0%, 84%, 1);
$vm-color-gray-light-82: hsla(0, 0%, 82%, 1);
$vm-color-gray-light-80: hsla(0, 0%, 80%, 1);

$vm-color-gray-75: hsla(0, 0%, 75%, 1);
$vm-color-gray-70: hsla(0, 0%, 70%, 1);
$vm-color-gray-65: hsla(0, 0%, 65%, 1);
$vm-color-gray-60: hsla(0, 0%, 60%, 1);
$vm-color-gray-55: hsla(0, 0%, 55%, 1);
$vm-color-gray-50: hsla(0, 0%, 50%, 1);
$vm-color-gray-45: hsla(0, 0%, 45%, 1);
$vm-color-gray-40: hsla(0, 0%, 40%, 1);
$vm-color-gray-35: hsla(0, 0%, 35%, 1);
$vm-color-gray-30: hsla(0, 0%, 30%, 1);
$vm-color-gray-25: hsla(0, 0%, 25%, 1);

$vm-color-gray-dark-20: hsla(0, 0%, 20%, 1);
$vm-color-gray-dark-18: hsla(0, 0%, 18%, 1);
$vm-color-gray-dark-16: hsla(0, 0%, 16%, 1);
$vm-color-gray-dark-14: hsla(0, 0%, 14%, 1);
$vm-color-gray-dark-12: hsla(0, 0%, 12%, 1);
$vm-color-gray-dark-10: hsla(0, 0%, 10%, 1);
$vm-color-gray-dark-8: hsla(0, 0%, 8%, 1);
$vm-color-gray-dark-6: hsla(0, 0%, 6%, 1);
$vm-color-gray-dark-4: hsla(0, 0%, 4%, 1);
$vm-color-gray-dark-2: hsla(0, 0%, 2%, 1);

$vm-color-black-0: hsla(0, 0%, 0%, 1);
$vm-color-black-0a75: hsla(0, 0%, 0%, .75);
$vm-color-black-0a50: hsla(0, 0%, 0%, .50);
$vm-color-black-0a25: hsla(0, 0%, 0%, .25);

$bah: hsla(200, 100%, 25%, 1);
$vm-color-blue-25: hsla(200, 68%, 25%, 1);
$vm-color-blue-53: hsla(200, 68%, 53%, 1);
$vm-color-blue-85: hsla(200, 68%, 85%, 1);
$vm-color-blue-95: hsla(200, 68%, 95%, 1);

$vm-color-green-light-40: hsla(82, 95%, 40%, 1);
$vm-color-green-30: hsla(120, 100%, 30%, 1);
$vm-color-green-60: hsla(120, 100%, 60%, 1);

$vm-color-yellow-40: hsla(55, 100%, 40%, 1);

$vm-color-orange-40: hsla(37, 100%, 40%, 1);

$vm-color-red-40: hsla(0, 100%, 40%, 1);
$vm-color-red-30: hsla(0, 100%, 30%, 1);
$vm-color-red-90: hsla(0, 100%, 90%, 1);

// Application color definitions
$vm-border-color-one: $vm-color-gray-light-90;
$vm-primary-color-one: $vm-color-blue-53;
$vm-secondary-color-one: $vm-color-blue-95;
$vm-table-header-color-one: $vm-color-blue-85;
$vm-table-row-color-one: $vm-color-white-100;
$vm-table-row-color-two: $vm-color-gray-light-96;
$vm-hover-color-one: $vm-color-blue-95;
$vm-hover-color-two: transparentize($vm-primary-color-one, 0.5);
$vm-hover-color-three: $vm-color-blue-25;
$vm-green-color-one: $vm-color-green-light-40;
$vm-green-color-two: $vm-color-green-30;
$vm-green-color-three: $vm-color-green-60;
$vm-yellow-color-one: $vm-color-yellow-40;
$vm-orange-color-one: $vm-color-orange-40;
$vm-red-color-one: $vm-color-red-40;
$vm-red-color-two: $vm-color-red-30;
$vm-not-valid-color-one: $vm-color-red-90;
$vm-blue-color-one: $vm-color-blue-53;
$vm-gray-color-one: $vm-color-gray-45;
$vm-light-gray-color-one: $vm-color-gray-light-90;
$vm-light-gray-color-two: $vm-color-gray-light-96;
$vm-transparent-blue-color-one: transparentize($vm-primary-color-one, 0.5);
$vm-white-color-one: $vm-color-white-100;
$vm-black-color-one: $vm-color-black-0;
$vm-video-overlay-light-one: $vm-color-black-0a50;
$vm-video-overlay-dark-one: $vm-color-black-0a75;
$vm-video-overlay-text-color-one: $vm-color-white-100;
$vm-modal-backdrop-color: $vm-color-black-0a50;
$vm-active-row-color-one: $vm-color-blue-85;
$vm-video-background-color-one: $vm-color-gray-dark-14;
$vm-transparent-gray-color-one: $vm-color-black-0a25;

.vm-green-text-one {
  color: $vm-green-color-one;
}

.vm-yellow-text-one {
  color: $vm-yellow-color-one;
}

.vm-orange-text-one {
  color: $vm-orange-color-one;
}

.vm-red-text-one {
  color: $vm-red-color-one;
}

.vm-blue-text-one {
  color: $vm-blue-color-one;
}
