#unauthorized-page {

  #page-404 {

    .violationBox {
      width: 90%;
      border: 2px;
      max-width: 500px;
      margin: auto;
    }
  }
}
