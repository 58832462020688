#portal-network {

  #portal-network-search-container {
    display: table;
    width: 100%;
  }

  #portal-network-search-row {
    display: table-row;
  }

  #portal-network-search-box-cell {
    display: table-cell;
    width: 60%;
  }

  #portal-network-advanced-filters-cell {
    display: table-cell;
    width: 40%;
    vertical-align: middle;
    padding-left: 15px;
  }

  #portal-network-advanced-filters-cell {
    @extend .hoverable-blue-text-one;
  }

  #portal-network-advanced-filters-container {
    display: block;
    background-color: $vm-secondary-color-one;
    margin-top: 10px;
    padding: 10px;
  }

  #portal-network-tabs {
    margin-top: 10px;
  }

  .filters-container {
    margin-top: 10px;
  }

  .filter-container {
    display: inline-block;
    width: 215px;
    float: left;
    margin-left: 3px;
    margin-right: 3px;
  }

  .clear-filters-button {
    margin-right: 5px;
  }
}
