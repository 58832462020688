#season-edit-modal {

  .season-edit-area {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .generic-modal-footer-button-container {
    display: flex;
    float: none;
    justify-content: flex-start;
    #season-edit-deactivate-btn {
      margin-right: auto;
    }

    #season-edit-cancel-btn {
      margin-left: auto;
      margin-right: 10px;
    }

    .btn {
      width: 100px;
    }
  }

  .required-warning {
    color: red;
    font-size: 10px;
  }
}