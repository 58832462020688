#portal-stats {

  .stats-selectors {

  }

  .stats-filters {

  }

  .presets-input {
    float: left;
    width: 150px;
  }

  .year-input {
    float: right;
    width: 100px;
    margin-right: 5px;
  }

  .team-input {
    float: right;
    width: 350px;
  }

  .matches-container {
    background-color: $vm-table-row-color-one;
    padding: 0 5px 5px 5px;
  }

  .matches-title {
    font-size: 12px;
  }

  .filters-container {

  }

  .filters-group {
    float: left;
    background-color: $vm-table-row-color-two;
    padding: 0 5px 5px 5px;
    margin: 2px;
    border-radius: 5px;
  }

  .filters-title {
    text-align: center;
    font-size: 12px;
    font-weight: 800;
  }

  .filter-input {
    position: relative;
    float: right;
    width: 25%;
    z-index: 1;
  }

  .stats-tabs {
    border-bottom: 2px solid $vm-table-header-color-one;
  }

  .table-state-message {
    background-color: $vm-gray-color-one;
    text-align: center;
  }

  .table-state-message-text {
    color: $vm-white-color-one;
  }

  .loadingErrorRow {
    display: table;
    width: 100%;
    height: 75px;
     font-weight: bold;
    background-color: #fbd5d5;
    color: red;
    span {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
  }

}
