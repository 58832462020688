vm-comment-reply {

  font-size: 12px;

  .vm-comment-reply-details {
    display: table-cell;
    vertical-align: top;
    width: 15%;
    border-top: 1px solid $vm-border-color-one;
  }

  .vm-comment-reply-name-text {
    font-weight: 800;
  }

  .vm-comment-reply-body {
    display: table-cell;
    vertical-align: top;
    padding-left: 10px;
    border-top: 1px solid $vm-border-color-one;
  }

  .vm-comment-reply-body-text {
    font-weight: 800;
  }

  .vm-comment-reply-moment {
    display: table-cell;
    vertical-align: middle;
    width: 20%;
    text-align: right;
    border-top: 1px solid $vm-border-color-one;
  }

  .vm-comment-reply-moment-text {
    font-size: 11px;
  }
}
