.toggle-setting-directive {
  $xxSmallToggleSwitchSize: 10px;
  $xxSmallToggleSwitchLength: 20px;

  $xSmallToggleSwitchSize: 15px;
  $xSmallToggleSwitchLength: 30px;

  $smallToggleSwitchSize: 20px;
  $smallToggleSwitchLength: 40px;

  $mediumToggleSwitchSize: 25px;
  $mediumToggleSwitchLength: 50px;

  $largeToggleSwitchSize: 30px;
  $largeToggleSwitchLength: 60px;

  $xLargeToggleSwitchSize: 35px;
  $xLargeToggleSwitchLength: 70px;

  $xxLargeToggleSwitchSize: 40px;
  $xxLargeToggleSwitchLength: 80px;

  position: relative;
  height: inherit;

  // default theme size is 'small' and default color scheme is 'dark'
  .toggle-setting-container {

  }

  .toggle-setting-left-label {
    float: left;
    cursor: pointer;

    &:hover {
      color: $vm-primary-color-one;
    }
  }

  .toggle-setting-label-text {

  }

  .toggle-switch-container {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto auto;

    height: $smallToggleSwitchSize;
    width: $smallToggleSwitchLength;
  }

  .toggle-slide {
    position: absolute;
    width: $smallToggleSwitchLength - 5px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto auto;

    height: $smallToggleSwitchSize - 5px;
    border-radius: $smallToggleSwitchSize - 5px;
    // off
    background-color: gray;
    // on
    &.on {
      background-color: $vm-primary-color-one;
    }
  }

  .toggle-switch {
    position: absolute;
    height: inherit;
    cursor: pointer;

    width: $smallToggleSwitchSize;
    border-radius: $smallToggleSwitchSize / 2;

    // off
    background-color: hsl(0, 0%, 77%);
    &:hover {
      background-color: $vm-secondary-color-one;
    }
    // on
    &.on {
      right: 0;
      background-color: white;
      &:hover {
        background-color: $vm-secondary-color-one;
      }
    }
  }

  // ALIGNMENTS
  &.left-alignment {
    .toggle-switch-container {
      left: 0;
    }
  }

  &.right-alignment {
    .toggle-switch-container {
      right: 0;
    }
  }

  &.center-alignment {
    .toggle-switch-container {
      left: 0;
      right: 0;
    }
  }

  // COLOR SCHEMES
  &.dark-color-scheme {
    // default - see original definitions above
  }

  &.light-color-scheme {
    .toggle-switch-container {

    }

    .toggle-slide {
      // off
      background-color: darken($vm-gray-color-one, 20%);
      // on
      &.on {
        background-color: darken($vm-green-color-one, 20%);
      }
    }

    .toggle-switch {
      // off
      background-color: $vm-gray-color-one;
      &:hover {
        background-color: darken($vm-gray-color-one, 10%);
      }
      // on
      &.on {
        background-color: $vm-green-color-one;
        &:hover {
          background-color: darken($vm-green-color-one, 10%);
        }
      }
    }
  }

  &.disabled {
    .toggle-setting-left-label {
      cursor: not-allowed;
    }

    .toggle-switch-container {

    }

    .toggle-slide {
      // off
      background-color: darken($vm-light-gray-color-one, 20%);
      // on
      &.on {
        background-color: darken($vm-light-gray-color-one, 20%);
      }
    }

    .toggle-switch {
      cursor: not-allowed;
      // off
      background-color: $vm-light-gray-color-one;
      &:hover {
        background-color: $vm-light-gray-color-one;
      }
      // on
      &.on {
        background-color: $vm-light-gray-color-one;
        &:hover {
          background-color: $vm-light-gray-color-one;
        }
      }
    }
  }

  // SIZES
  &.xx-small-size {
    .toggle-switch-container {
      height: $xxSmallToggleSwitchSize;
      width: $xxSmallToggleSwitchLength;
    }

    .toggle-slide {
      width: $xxSmallToggleSwitchLength - 5px;
      height: $xxSmallToggleSwitchSize - 5px;
      border-radius: $xxSmallToggleSwitchSize - 5px;
    }

    .toggle-switch {
      width: $xxSmallToggleSwitchSize;
      border-radius: $xxSmallToggleSwitchSize / 2;
    }
  }

  &.x-small-size {
    .toggle-switch-container {
      height: $xSmallToggleSwitchSize;
      width: $xSmallToggleSwitchLength;
    }

    .toggle-slide {
      width: $xSmallToggleSwitchLength - 5px;
      height: $xSmallToggleSwitchSize - 5px;
      border-radius: $xSmallToggleSwitchSize - 5px;
    }

    .toggle-switch {
      width: $xSmallToggleSwitchSize;
      border-radius: $xSmallToggleSwitchSize / 2;
    }
  }

  &.small-size {
    // default - see original definitions above
  }

  &.medium-size {
    .toggle-switch-container {
      height: $mediumToggleSwitchSize;
      width: $mediumToggleSwitchLength;
    }

    .toggle-slide {
      width: $mediumToggleSwitchLength - 5px;
      height: $mediumToggleSwitchSize - 5px;
      border-radius: $mediumToggleSwitchSize - 5px;
    }

    .toggle-switch {
      width: $mediumToggleSwitchSize;
      border-radius: $mediumToggleSwitchSize / 2;
    }
  }

  &.large-size {
    .toggle-switch-container {
      height: $largeToggleSwitchSize;
      width: $largeToggleSwitchLength;
    }

    .toggle-slide {
      width: $largeToggleSwitchLength - 5px;
      height: $largeToggleSwitchSize - 5px;
      border-radius: $largeToggleSwitchSize - 5px;
    }

    .toggle-switch {
      width: $largeToggleSwitchSize;
      border-radius: $largeToggleSwitchSize / 2;
    }
  }

  &.x-large-size {
    .toggle-switch-container {
      height: $xLargeToggleSwitchSize;
      width: $xLargeToggleSwitchLength;
    }

    .toggle-slide {
      width: $xLargeToggleSwitchLength - 5px;
      height: $xLargeToggleSwitchSize - 5px;
      border-radius: $xLargeToggleSwitchSize - 5px;
    }

    .toggle-switch {
      width: $xLargeToggleSwitchSize;
      border-radius: $xLargeToggleSwitchSize / 2;
    }
  }

  &.xx-large-size {
    .toggle-switch-container {
      height: $xxLargeToggleSwitchSize;
      width: $xxLargeToggleSwitchLength;
    }

    .toggle-slide {
      width: $xxLargeToggleSwitchLength - 5px;
      height: $xxLargeToggleSwitchSize - 5px;
      border-radius: $xxLargeToggleSwitchSize - 5px;
    }

    .toggle-switch {
      width: $xxLargeToggleSwitchSize;
      border-radius: $xxLargeToggleSwitchSize / 2;
    }
  }
}
