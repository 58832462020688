.toggleable-transclude-directive {
  position: relative;

  .close-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  .close-x {
    cursor: pointer;

    &:hover {
      color: $vm-primary-color-one;
    }
  }
}
