#video-event-details-directive {

  position: relative;

  .video-event-details-container {

  }

  .video-event-detail {
    float: left;
    text-align: center;
    min-width: 55px;
    padding: 5px 5px;
  }

  .video-event-detail-title {
    font-size: 10px;
    font-weight: 800;
  }

  .video-event-detail-title-text {

  }

  .video-event-detail-value {
    font-size: 10px;
    font-weight: 100;
  }

  .video-event-detail-value-text {

  }
}
