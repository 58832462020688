/**
 * This is the main application stylesheet. It should include or import all
 * stylesheets used throughout the application as this is the only stylesheet in
 * the Gulp configuration that is automatically processed.
 */
@import '_variables';
@import '_framework';
@import '_typography';
@import '_simplicicons';
@import '_md-icons';
@import '_ui';
@import '_loader';

@import '../../modules/auth/styles/auth';

/* These imports are kept after compiling to import the plain css later */
@import 'styles-includes/select.min.css';
@import 'styles-includes/videogular.css';
@import 'styles-includes/hotkeys.min.css';
@import 'styles-includes/angular-ui-switch.css';

@import '_colorStyle';
@import '_sizeStyle';
@import '_common';
@import '_commonStyle';

@import '../../app/_appStyle';
@import '../../modules/portal/views/_portalAbstractStyle';
@import '../../modules/portal/views/settingsAbstract/_settingsAbstractStyle';
@import '../../modules/portal/directives/matchStatusIcons/_matchStatusIconsStyle';
@import '../../modules/portal/directives/loading/_loadingStyle';
@import '../../modules/portal/directives/genericTableTwo/_genericTableTwoStyle';
@import '../../modules/portal/directives/genericTableTwo/genericTableTwoHeader/_genericTableTwoHeaderStyle';
@import '../../modules/portal/directives/genericTableTwo/genericTableTwoRow/_genericTableTwoRowStyle';
@import '../../modules/portal/directives/genericTableTwo/genericTableTwoFooter/_genericTableTwoFooterStyle';
@import '../../modules/portal/directives/leftMenu/_leftMenuStyle';
@import '../../modules/portal/directives/vmCallout/_vmCalloutStyle';
@import '../../modules/portal/directives/quickTip/_quickTipStyle';
@import '../../modules/portal/directives/vmTabs/_vmTabsStyle';
@import '../../modules/portal/directives/vmInputBox/_vmInputBoxStyle';
@import '../../modules/portal/directives/vmInputBox/inputPrediction/_inputPredictionStyle';
@import '../../modules/portal/directives/toggleableTransclude/_toggleableTranscludeStyle';
@import '../../modules/portal/directives/quickStats/_quickStatsStyle';
@import '../../modules/portal/directives/quickStats/largeStatCard/_largeStatCardStyle';
@import '../../modules/portal/directives/quickStats/smallStatCard/_smallStatCardStyle';
@import '../../modules/portal/directives/quickStats/_quickStatsTooltipStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/_vmMatchVideoPlayerStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/videoScoreboard/_videoScoreboardStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/videoEventDetails/_videoEventDetailsStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/videoSettings/_videoSettingsStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/videoActionMenu/_videoActionMenuStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/videoShortcuts/_videoShortcutsStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/videoCloseComments/_videoCloseCommentsStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/videoControlsPanel/_videoControlsPanelStyle';
@import '../../modules/portal/directives/vmMatchVideoPlayer/volumeControl/_volumeControlStyle';
@import '../../modules/portal/directives/vmOptionDrawer/_vmOptionDrawerStyle';
@import '../../modules/portal/directives/toggleSetting/_toggleSettingStyle';
@import '../../modules/portal/directives/vmMatchEventFilter/_vmMatchEventFilterStyle';
@import '../../modules/portal/directives/vmMatchEventFilter/eventFilterTable/_eventFilterTableStyle';
@import '../../modules/portal/directives/vmMatchEventFilter/eventFilterTableRow/_eventFilterTableRowStyle';
@import '../../modules/portal/directives/vmMatchEventFilter/eventFilterSelector/_eventFilterSelectorStyle';
@import '../../modules/portal/directives/vmMatchComments/_vmMatchCommentsStyle';
@import '../../modules/portal/directives/vmMatchComments/vmComment/_vmCommentStyle';
@import '../../modules/portal/directives/vmMatchComments/vmCommentReply/_vmCommentReplyStyle';
@import '../../modules/portal/directives/portalNotifications/_portalNotificationsStyle';
@import '../../modules/portal/directives/vmMatchActions/_vmMatchActionsStyle';
@import '../../modules/portal/directives/portalHeaderNew/_portalHeaderNewStyle';
@import '../../modules/portal/directives/vmTag/_vmTagStyle';
@import '../../modules/portal/directives/vmTooltip/_vmTooltipStyle';
@import '../../modules/portal/directives/directiveInjector/_directiveInjectorStyle';
@import '../../modules/portal/directives/vmCollapsible/_vmCollapsibleStyle';
@import '../../modules/portal/directives/vmCollapsible/vmCollapsibleSection/_vmCollapsibleSectionStyle';
@import '../../modules/portal/directives/statsMatchRow2/_statsMatchRow2Style';
@import '../../modules/portal/views/stats/statMatchCard/_statMatchCardStyle';
@import '../../modules/portal/views/stats/statFilterCard/_statFilterCardStyle';
@import '../../modules/portal/views/reports/reportsStyle';
@import '../../modules/portal/modals/confirmReportsModal/confirmReportsModal';
@import '../../modules/portal/directives/scoringTable/_scoringTableStyle';
@import '../../modules/portal/directives/statsTable/_statsTableStyle';
@import '../../modules/portal/modals/genericTableModal/_genericTableModalStyle';
@import '../../modules/portal/modals/shareMatchModal/_shareMatchModalStyle';
@import '../../modules/portal/modals/dvwDownloadAgreementModal/_dvwDownloadAgreementModalStyle';
@import '../../modules/portal/modals/beachLineupModal/_beachLineupModalStyle';
@import '../../modules/portal/modals/sendToHudlModal/_sendToHudlModalStyle';
@import '../../modules/portal/modals/scheduleModal/_scheduleModalStyle';
@import '../../modules/portal/modals/scheduleModal/duplicateScheduleTableRow/_duplicateScheduleTableRowStyle';
@import '../../modules/portal/modals/scheduleModal/venueOverlapWarningTableRow/_venueOverlapWarningTableRowStyle';
@import '../../modules/portal/modals/vmAdminTeamModal/_vmAdminTeamModalStyle';
@import '../../modules/portal/modals/matchAccessModal/_matchAccessModalStyle';
@import '../../modules/portal/modals/matchAccessModal/matchAccessModalMatchViewTeamTableRow/_matchAccessModalMatchViewTeamTableRowStyle';
@import '../../modules/portal/modals/matchAccessModal/matchAccessModalMatchViewUserTableRow/_matchAccessModalMatchViewUserTableRowStyle';
@import '../../modules/portal/modals/matchAccessModal/matchAccessModalTeamViewTableRow/_matchAccessModalTeamViewTableRowStyle';
@import '../../modules/portal/modals/matchAccessModal/matchAccessModalUserViewTableRow/_matchAccessModalUserViewTableRowStyle';
@import '../../modules/portal/modals/vmAdminRosterPlayerModal/_vmAdminRosterPlayerModalStyle';
@import '../../modules/portal/modals/statsFilterModal/_statsFilterModalStyle';
@import '../../modules/portal/modals/statsFilterModal/matchFiltersSection/_matchFiltersSectionStyle';
@import '../../modules/portal/modals/statsFilterModal/advancedFiltersSection/_advancedFiltersSectionStyle';
@import '../../modules/portal/modals/statsFilterModal/statsColumnsSection/_statsColumnsSectionStyle';
@import '../../modules/share/views/shareDownload/_shareDownloadStyle';
@import '../../modules/auth/views/401/_401Style';
@import '../../modules/auth/views/404/_404Style';
@import '../../modules/auth/views/unauthorized/_unauthorizedStyle';
@import '../../modules/portal/views/dashboard/_dashboardStyle';
@import '../../modules/portal/views/dashboard/dashboardMatchesTableRow/_dashboardMatchesTableRowStyle';
@import '../../modules/portal/views/matches/_matchesStyle';
@import '../../modules/portal/views/matches/myMatchesTableRow/_myMatchesTableRowStyle';
@import '../../modules/portal/views/matches/practicesTableRow/_practicesTableRowStyle';
@import '../../modules/portal/views/match/_matchStyle';
@import '../../modules/portal/views/profile/acceptAccountLinkInvite/_acceptAccountLinkInviteStyle';
@import '../../modules/portal/views/notifications/_notificationsStyle';
@import '../../modules/portal/views/stats/_statsStyle';
@import '../../modules/portal/views/network/_networkStyle';
@import '../../modules/portal/views/systemAdministration/_systemAdministrationStyle';
@import '../../modules/portal/views/systemAdministration/teams/_systemAdministrationTeamsStyle';
@import '../../modules/portal/views/systemAdministration/teams/teamsTableRow/_teamsTableRowStyle';
@import '../../modules/portal/views/systemAdministration/matchAccess/_systemAdministrationMatchAccessStyle';
@import '../../modules/portal/views/systemAdministration/matchAccess/matchAccessMatchTableRow/_matchAccessMatchTableRowStyle';
@import '../../modules/portal/views/systemAdministration/matchAccess/matchAccessUserTableRow/_matchAccessUserTableRowStyle';
@import '../../modules/portal/views/systemAdministration/matchAccess/matchAccessSearchMatchesSection/_matchAccessSearchMatchesSectionStyle';
@import '../../modules/portal/views/systemAdministration/matchAccess/matchAccessSearchEntitiesSection/_matchAccessSearchEntitiesSectionStyle';
@import '../../modules/portal/views/systemAdministration/rosters/_systemAdministrationRostersStyle';
@import '../../modules/portal/views/systemAdministration/rosters/rostersTableRow/_rostersTableRowStyle';
@import '../../modules/portal/views/systemAdministration/seasons/_seasonsStyle';
@import '../../modules/portal/views/systemAdministration/seasons/seasonsTableRow/_seasonsTableRowStyle';
@import '../../modules/portal/views/systemAdministration/seasons/seasonEditModal/_seasonEditStyle';
@import '../../modules/portal/views/systemAdministration/conferences/_conferencesStyle';
@import '../../modules/portal/views/systemAdministration/conferences/conferenceModal/_conferencesModalStyle';
@import '../../modules/portal/views/schedule/_scheduleStyle';
@import '../../modules/portal/views/schedule/scheduleMatchesTableRow/_scheduleMatchesTableRowStyle';
@import '../../modules/portal/views/schedule/schedulePracticesTableRow/_schedulePracticesTableRowStyle';