.small-stat-card-directive {
  $poorStatColor: hsl(0, 100%, 41%);
  $goodStatColor: hsl(75, 100%, 41%);

  position: relative;
  padding: 0 20px;

  .card-title {

  }

  .card-title-text {
    font-weight: 800;
  }

  .card-sub-title {

  }

  .card-sub-title-text {
    font-size: 12px;
    font-weight: 100;
  }

  .card-stats-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .card-stats-table-row {
    display: table-row;
  }

  .primary-stat {
    display: table-cell;
    vertical-align: middle;
    width: 60%;
  }

  .primary-stat-text {
    font-size: 25px;
    font-weight: 800;
  }

  .secondary-stats-container {
    display: table-cell;
    vertical-align: middle;
    width: 40%;
  }

  .secondary-stat-one {

  }

  .secondary-stat-one-text {

  }

  .secondary-stat-two {

  }

  .secondary-stat-two-text {

  }

  .poor-stat {
    color: $poorStatColor;
  }

  .good-stat {
    color: $goodStatColor;
  }
}
