.tag-container {
  $tagRadius: 5px;

  position: relative;
  overflow: hidden;
  margin: 5px;
  float: none;

  .tag-name {
    background-color: $vm-primary-color-one;
    color: $vm-white-color-one;
    float: left;
    border-top-left-radius: $tagRadius;
    border-bottom-left-radius: $tagRadius;
    padding: 0 5px;
    line-height: 25px;
  }

  .tag-name-text {

  }

  .tag-remove {
    @extend .hoverable-gray-red-text-one;
    background-color: $vm-border-color-one;
    float: left;
    border-top-right-radius: $tagRadius;
    border-bottom-right-radius: $tagRadius;
    padding: 0 5px;
    line-height: 25px;
  }
}
