.stat-match-card-directive {

  position: relative;
  float: left;
  padding: 1px;

  .match-card-container {
    text-align: center;
    @extend .hoverable-light-gray-transparent-blue-background-one;
    background-color: $vm-light-gray-color-one;
    height: 40px;
    width: 45px;
    border-radius: 5px;

    &.highlight {
      background-color: $vm-primary-color-one;
    }
  }

  .match-card-label {
    font-size: 10px;
    font-weight: 800;
  }

  .match-card-name {
    font-size: 10px;
    font-weight: 800;
  }

  .match-card-details {
    font-size: 8px;
  }

  .match-card-score {
    height: 15px;
    line-height: 15px;
    background-color: hsla(0, 0%, 100%, 0.5);
  }

  .match-card-score-text {
    font-weight: 800;
  }

  .won {
    color: $vm-green-color-two;
  }

  .lost {
    color: $vm-red-color-two;
  }
}
