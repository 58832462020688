#the-404-page {

	#page-404 {

	}

	#numbers {
    font-size: 250px;
  }

  @media (max-width: 550px) {

		#page-404 {
			height: 350px;
		}

		h1 {
      font-size: 30px;
    }

		h2 {
      font-size: 32px;
    }

		#numbers {
			font-size: 150px;
		}
	}
}
